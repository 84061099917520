<template>
  <div class="addBox">
    <!--  面包屑  -->
    <div
      class="mrj-layout-tabs-custom flex align-item-center"
      style="z-index:1;"
    >
      <div
        @click="changeRoute"
        class="mrj-layout-tabs-custom-index flex align-item-center cursor-pointer"
      >
        <div
          type="left"
          class="leftarrow"
          :class="imgflag?'leftarrow_hover':''"
          @mouseenter="imgflag=true"
          @mouseleave="imgflag=false"
        >
          <i class="meiye-icon meiye-fanghui"></i>
        </div>

        <span
          @mouseenter="imgflag=true"
          @mouseleave="imgflag=false"
        >{{ $route.meta.title }}</span>
      </div>
      <span class="mrj-layout-tabs-custom-fenge">/</span>
      <span class="mrj-layout-tabs-custom-inner-page"> 查看 </span>
    </div>
    <!-- 内容区 -->
    <div class="add"> 
      <TagsSider
        :tabs="shoptabs"
        v-model="activeKey"
      />
      <a-layout-content
        class="main-ctn-2"
        id="mrj-scroll-main"
        @scroll="handleWrapScroll"
      >
        <!-- 基本信息 -->
        <div
          id="product-base"
          data-anchor="product-base"
        >
          <h2 class="twoh2">
            <span class="tpointer"></span>
            <span class="ttxt"> {{form.goods_name}} </span>
            <span :class="form.is_show==1?'onsales':'offsales'">{{form.is_show==1?'在售':'停售'}}</span>
          </h2>
          <h4 class="shopCodes">商品码：{{ form.goods_bn }}</h4>
          <a-row class="mgb-30 mgl-12">
            <span class="leftBabletxt">来源</span>
            <span class="rightBabletxt">总部创建</span>
            <span class="leftBabletxt">商品别名</span>
            <span class="rightBabletxt"> {{form.goods_alias_name || '--'}}</span>
          </a-row>
          <a-row class="mgb-30 mgl-12">
            <span class="leftBabletxt">品牌</span>
            <span class="rightBabletxt">{{form.goods_brand || ''}}</span>
            <span class="leftBabletxt">类别</span>
            <span class="rightBabletxt">{{(form.goods_type==1||form.goods_type==3)?'客装':'院装'}}</span>
          </a-row>
          <a-row class="mgb-30 mgl-12">
            <span class="leftBabletxt">标准单位</span>
            <span class="rightBabletxt">{{form.goods_unit}}</span>
            <span class="leftBabletxt">商城分类</span>
            <span class="rightBabletxt">{{form.store_category_name||'--'}}</span>
          </a-row>
          <a-row class="mgb-30 mgl-12">
            <!-- <span class="leftBabletxt">销售时间</span>
            <span class="rightBabletxt">{{form.updated_at}}</span> -->
            <span class="leftBabletxt">合作项目</span>
            <span class="rightBabletxt">{{(form.goods_type==1||form.goods_type==2)?'否':'是'}}</span>
            <span class="leftBabletxt">赠送</span>
            <span class="rightBabletxt">{{ form.is_give?'不允许赠送':'允许赠送'}}</span>

          </a-row>
          <a-row class="mgb-42 mgl-12">
          </a-row>
        </div>
        <!-- 产品规格  -->
        <div
          id="product-detail"
          data-anchor="product-detail"
        >
          <h2 class="twoh2 ">
            <span class="tpointer"></span>
            <span class="ttxt">产品规格</span>
          </h2>
          <div class="mgl-12 mgt-16">
            <a-row class="mgb-30 ">
              <span class="leftBabletxt">产品规格</span>
              <span class="rightBabletxt">{{ form.goods_attr?.length?'多规格':'单规格' }}</span>
            </a-row>

            <!-- 生成的表格 -->
            <div class="guigeTablebox  mgb-30">
              <a-table
                v-if="goodSpecstableData && goodSpecstableData.length>0"
                :pagination="false"
                :rowKey="(record,index)=>{return index}"
                :columns="goods_specs_clomns"
                :data-source="goodSpecstableData"
                class="guigeTable"
                :scroll="{ x: getAutowidth() }"
              >

                <template
                  slot="product_image"
                  slot-scope="action, record"
                >
                  <img
                    style="width:42px;height: 42px;"
                    v-if="record.product_image"
                    :src="record.product_image"
                    alt="avatar"
                  />
                </template>
                <template
                  slot="product_price"
                  slot-scope="action, record"
                >
                  {{ "￥"+record.product_price }}
                </template>
                <!-- product_market_price -->
                <template
                  slot="product_market_price"
                  slot-scope="action, record"
                >
                  {{ "￥"+record.product_market_price }}
                </template>
                <template
                  slot="product_bn"
                  slot-scope="action, record"
                >
                  {{  record.product_bn }}
                </template>
                <!-- 耗材 -->
                <span
                  slot="consume_material"
                  style="display: inline-block; "
                >
                  <!-- style="display: inline-block; min-width: 238px;" -->
                  耗材
                  <a-tooltip :overlayStyle='{"width":"324px"}'>
                    <template slot="title">
                      指服务进行服务时所需要消耗的产品
                      耗材消耗量：指服务进行服务时所需要消耗的产品数量，以产品基本单位为基数进行计算。
                      例如：产品基本单位是“盒”，每盒含有10支，服务服务时需要消耗“2支”，那么该耗材的消耗量应填写0.2
                    </template>
                    <a-icon
                      type="info-circle"
                      :style="{ color: '#819190' }"
                    />
                  </a-tooltip>

                </span>
                <template
                  slot="consume_material"
                  slot-scope="action, record"
                >
                  <div class="consumeBox">
                    <div
                      class="c-box "
                      v-if=" record.consume_material&&record.consume_material.length"
                    >
                      <div
                        class="consumeBox_item"
                        v-for="(it,idx) in record.consume_material"
                        :key="idx"
                      >
                        <p class="tabelBody_itemsp1"><span class="sp_p1">{{it.title  || it.goods_name}}</span> </p>
                        <p class="tabelBody_itemsp2 flex align-item-center"><span class="sp_p2">{{it.product_bn || record.product_bn}}</span> <span class="sp_line"></span> <span class="sp_p2">消耗量：<span class="baseColor11">{{it.consume_material_num}}</span>{{ it.goods_unit?it.goods_unit:'个' }}</span> </p>
                      </div>

                    </div>
                    <div v-else>暂无数据</div>
                  </div>

                </template>
              </a-table>
            </div>
          </div>
        </div>
        <!-- 其他信息 -->
        <div
          class="mgt-40"
          id="product-user"
          data-anchor="product-user"
        >
          <h2 class="twoh2 mgb-20">
            <span class="tpointer"></span>
            <span class="ttxt">其他信息</span>
          </h2>
          <a-row class="mgb-30 mgl-12">
            <span class="leftBabletxt">配送支持</span>
            <span class="rightBabletxt">{{form.delivery_method||'--' }}</span>
            <span class="leftBabletxt">运费设置</span>
            <span class="rightBabletxt">{{delivery_money==1?'包邮':' 运费模板（商城默认模板）'}}</span>
          </a-row>
          <a-row class="mgb-30 mgl-12">
            <span class="leftBabletxt">上架状态</span>
            <span class="rightBabletxt">{{form.is_show?'下架':'上架'}}</span>
          </a-row>
          <a-row class="mgb-30 mgl-12 flex ">
            <span class="leftBabletxt pdt-5">轮播图</span>
            <div
              class=" "
              v-if="form.goods_slider&&form.goods_slider.length"
            >
              <span
                class="swichers"
                v-for="(it,idx) in form.goods_slider"
                :key="idx"
              >
                <img
                  :src="it"
                  alt=""
                >
              </span>
              <!-- <upload-image
                :multiple="5"
                :img="form.goods_slider"
                ref="uploadCom"
              ></upload-image> -->
            </div>
            <span
              class="rightBabletxt"
              v-else
            >--</span>
          </a-row>
          <a-row class="mgb-30 mgl-12 flex">
            <span class="leftBabletxt  pdt-5">封面</span>
            <div
              class="swichers "
              v-if="form.goods_image"
            >
              <img
                :src="form.goods_image"
                alt=""
                class=""
              >

            </div>
            <span
              class="rightBabletxt"
              v-else
            >--</span>
          </a-row>
          <a-row class="mgb-30 mgl-12 flex">
            <span class="leftBabletxt ">描述</span>
            <div class="flex-1 ">{{ form.goods_desc || '--'}}</div>
          </a-row>
          <a-row class="mgb-30 mgl-12 flex">
            <span class="leftBabletxt pdt-5">产品详情</span>
            <div class="flex ">
              <shop-editor
                class="goods_editor"
                @transfer="getUser"
                v-model="form.goods_content"
              >
              </shop-editor>
            </div>
          </a-row>
        </div>
      </a-layout-content>

    </div>
  </div>

</template>
<script>
import moment from 'moment';
import config from '../config';
import {
  GetmanageGoodslist,
  GetGoodsdetails,
} from '@/api/mall'

import { GetgoodsbrandsoptionsList } from '@/api/commodity'
import UploadImage from '../modules/drag'
import ShopEditor from '../modules/wangeditor'
import skuSetting from '../../../components/intimate/skuSetting/index.vue'
import Msg from '@/components/mrj-menu/msg'
import storage from 'store'
import axios from 'axios'
import { pinyin } from 'pinyin-pro';
import { TagsSider } from '@/components'
export default {
  inject: ['reload'],
  components: { UploadImage, ShopEditor, skuSetting, TagsSider },
  data() {
    return {
      config,
      imgflag: false,
      shoptabs: [
        {
          id: "product-base",
          label: "基本信息",
        },
        {
          id: "product-detail",
          label: "产品规格",
        },
        {
          id: "product-user",
          label: "其他信息",
        },
      ],
      activeKey: 'product-base',
      setId: '',
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      headers: {
        "Authorization": localStorage.getItem("token"),
        "Content-Type": 'multipart/form-data'
      },

      form: {//表单数据
        goods_id: undefined,
        goods_name: undefined,
        goods_bn: undefined,
        goods_unit: undefined,
        is_give: 0,//0不允许 
        is_show: 1,
        goods_type_hz: 0,//合作项目 需要联动 goods_type   
        goods_attr: undefined,
        goods_attr_val: [{ product_image: '', product_price: '', product_bn: "" }],
        goods_desc: undefined,
        goods_image: undefined,
        goods_slider: undefined,
        goods_type: 1,
        goods_supplier: 1,
        goods_brand: undefined,
        finance_category_id: [],
        store_category_id: [],
        goods_category: [],
        goods_content: undefined,
        goods_shou_type: 1,
        goods_shou_end_time: null
      },
      // 规格相关
      goods_specs: {
        isSingle: 1,//1  1单规格 2多规格
        barcode: undefined,//批量设置货品码
        price: undefined,//批量设置单价
        product_market_price: undefined,//批量市场价
        product_image: undefined,//批量设置图片
        ischooseModehow: true,//选择规格model
        goods_attr: [
          // { attr_name: '', attr_values: '' ,id:0}
        ],
        goods_specs_id: undefined,
        goods_attr_val: [
        ],
        goods_specs_list: [
          {
            attr_name: '颜色', attr_values: ['红色', '白色'], id: 0
          },
          {
            attr_name: '容量', attr_values: ['500ml', '250ml'], id: 1
          },
          {
            attr_name: '量装', attr_values: ['袋装', '瓶装'], id: 2
          },
          {
            attr_name: '新增规格', attr_values: [], id: 9999
          },
        ],
        goods_specs_list0: [
          {
            attr_name: '颜色', attr_values: ['红色', '白色'], id: 0
          },
          {
            attr_name: '容量', attr_values: ['500ml', '250ml'], id: 1
          },
          {
            attr_name: '量装', attr_values: ['袋装', '瓶装'], id: 2
          },
          {
            attr_name: '新增规格', attr_values: [], id: 9999
          },
        ],
        // 总共的 总规格list
        new_goods_specs_list: [
        ],
        goods_specs_values_list: [],
        goods_specs_keys_list: [],
        // select暂存的规格 和规格值 确认之后才push
        goods_specs_selectobj: {

        }
      },
      ischangeGoodsSpecs1: true, //是否显示下拉框所属的规格
      showaddGoodsprivatespec: false,//自定义新增规格弹框
      singelguigeadd: {
        attr_name: '',
        attr_values: [],
      },
      // 表单头
      goods_specs_clomns: [
        {
          title: '规格图',
          dataIndex: 'product_image',
          key: 'product_image',
          className: 'pdl-24',
          ellipsis: true,
          scopedSlots: { customRender: 'product_image' },
        },
        {
          title: '市场价',
          dataIndex: 'product_market_price',
          key: 'product_market_price',
          ellipsis: true,
          scopedSlots: { customRender: 'product_market_price' },
        },

        {
          title: '货品码',
          // slots: { title: 'product_bn' },
          dataIndex: 'product_bn',
          key: 'product_bn',
          ellipsis: true,
          scopedSlots: { customRender: 'product_bn' },
        },
        {
          title: '库存',
          slots: { title: 'product_stock' },
          dataIndex: 'product_stock',
          key: 'product_stock',
          ellipsis: true,
          scopedSlots: { customRender: 'product_stock' },
        },
        {
          title: '实际销量',
          slots: { title: 'product_sales' },
          dataIndex: 'product_sales',
          key: 'product_sales',
          ellipsis: true,
          scopedSlots: { customRender: 'product_sales' },
        },
        {
          // title: '耗材',
          slots: { title: 'consume_material' },
          dataIndex: 'consume_material',
          key: 'consume_material',
          className: 'cum_width',
          // ellipsis: true, 
          scopedSlots: { customRender: 'consume_material' },
        },
      ],
      goodSpecstableData: [],
      goodSpecsSingletableData: [
        {
          product_price: undefined,
          product_market_price: undefined,
          product_image: undefined,
          product_bn: undefined,
          product_attr: '',
          product_is_def: 1,
          product_id: 0,
          product_cost_price: '',
          product_market_price: '',
          product_stock: ''
        }
      ],
      brandsList: [],//品牌列表
    };
  },
  created() {
    this.getBrands()
    if (this.$route.query.type == 3) {
      this.openDatails()
    }
    this.getAutowidth()
  },
  methods: {
    moment,

    // 获取详情信息
    getPartnerDetail(id) {
      partnerDetail({ id: id }).then(res => {
        console.log(res.data, "data");
        let list_no = [];
        let list_yes = [];
        res.data.commission_rule.forEach(element => {
          if (element.product_type == 1) {
            list_no.push(element);
          }
          if (element.product_type == 2) {
            list_yes.push(element);
          }
        });
        this.form = {
          ...res.data,
          share_price: res.data.subscription_setting.share_price,
          min_shares: res.data.subscription_setting.min_shares,
          premium_rate: res.data.subscription_setting.premium_rate,
          commission_rule_no: list_no,
          commission_rule_yes: list_yes,
        };
        // this.timeData = [res.data.start_date, res.data.end_date];
        this.application_timeData = [res.data.application_start_date, res.data.application_end_date]
        // console.log('this.timeData :>> ', this.timeData);
        console.log('this.form :>> ', this.form);
      })
    },
    // 描点
    handleWrapScroll(rec) {
      this.activeKey = this.$utils.onHandleWrapScroll(rec, this.shoptabs)
    },
    // 获取方案 id
    getPartnerGenerated() {
      partnerGenerated().then((res) => {
        this.form.scheme_identifier = res.data.scheme_identifier;
      })
    },
    // 富文本
    getUser(e) {
      console.log(e);
      this.form.goods_content = e
    },
    //上传
    handleChange1(info) {
      if (info.file.status === 'uploading') {
        this.permissionInfo.url_loading = true
        return
      }
      if (info.file.status === 'done') {
        const { response = {} } = info.file
        this.form.goods_attr_val[0].product_image = response?.data?.url
        this.permissionInfo.url_loading = false
      }
    },
    handleChange2(info) {
      if (info.file.status === 'uploading') {
        this.permissionInfo.pic_url_loading = true
        return
      }
      if (info.file.status === 'done') {
        const { response = {} } = info.file
        this.form.goods_image = response?.data?.url
        this.permissionInfo.pic_url_loading = false
      }
    },
    beforeUpload(file, fileList) {
      var self = this
      return new Promise((resolve, reject) => {
        const isLt1M = file.size / 1024 / 1024 > 1
        if (isLt1M) {
          self.$message.error('上传文件大于1MB!')
          reject(false)
        }
        if (fileList.length > 1) {
          self.$message.error('只能上传一张图片！')
          reject(false)
        }
        var fileNames = file.name.split('.')
        var fileType = fileNames[fileNames.length - 1].toLocaleLowerCase()
        var extList = ['jpg', 'png', 'jpeg']
        if (!extList.find((item) => item == fileType)) {
          self.$message.error('只能上传.jpg/.png/.jpeg类型的图片！')
          reject(false)
        }
        resolve(true)
      }).finally(() => {
      })
    },
    // 方案期效
    // onChangeTime(rec) {
    //   console.log('rec :>> ', rec);
    //   this.form.start_date = rec[0] ? rec[0].format('YYYY-MM-DD') : '';
    //   this.form.end_date = rec[1] ? rec[1].format('YYYY-MM-DD') : '';
    //   console.log('rec :>> ', this.form.start_date);
    //   console.log('rec :>> ', this.form.end_date);
    // },
    // 开放时间
    onChangeApplication(rec) {
      console.log('rec :>> ', rec);
      this.form.application_start_date = rec[0] ? rec[0].format('YYYY-MM-DD') : '';
      this.form.application_end_date = rec[1] ? rec[1].format('YYYY-MM-DD') : '';
    },
    onChangeIdentify(rec) {
      console.log('rec :>> ', rec);
      this.form.identify_end_date = rec ? rec.format('YYYY-MM-DD') : '';
    },
    handleOpenChange(status) {
      this.yearPickShow = status;
    },
    onChangeYear(rec) {
      console.log('rec :>> ', rec.format('YYYY'));
      this.form.dividend_ratio_year = rec ? rec.format('YYYY') : '';
      this.yearPickShow = false;
    },

    disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().endOf('day');
    },
    onStep(key) {
      let flag = false
      let flag2 = false
      if (key == 2) {
        if (!this.form.goods_name) return this.$message.warning('请填写产品名称！')
        if (!this.form.goods_bn) return this.$message.warning('请填写商品码！')
        if (!this.form.finance_category_id) return this.$message.warning('请选择财务分类！')
        if (!this.form.goods_unit) return this.$message.warning('请选择单位！')

        if (this.form.goods_shou_type == 2 && !this.form.goods_shou_end_time) return this.$message.warning('请填写截止日期！')
        if (this.form.goods_type == 1 && this.form.goods_type_hz == 0) {
          this.form.goods_type = 1
        }
        if (this.form.goods_type == 1 && this.form.goods_type_hz == 1) {
          this.form.goods_type = 3
        }
        if (this.form.goods_type == 2 && this.form.goods_type_hz == 0) {
          this.form.goods_type = 2
        }
        if (this.form.goods_type == 2 && this.form.goods_type_hz == 1) {
          this.form.goods_type = 4
        }
        if (this.form.store_category_id && this.form.store_category_id.length) {
          if (this.form.store_category_id.length < 3) {
            return this.$message.warning('必须选择三级，请完善商城分类再选择！')
          }

        }
        if (this.form.goods_category && this.form.goods_category.length) {
          if (this.form.goods_category.length < 3) {
            return this.$message.warning('必须选择三级，请完善门店分类再选择！')
          }
        }
        this.step = key
      }
      if (key == 3) {

        if (this.goods_specs.isSingle == 1) {
          if (!this.form.goods_attr_val[0].product_price) {
            // return this.$message.warning('请填写标准单价！')
            flag = true
          }
          if (!this.form.goods_attr_val[0].product_bn) {
            // return this.$message.warning('请填写商品码！')
            flag2 = true
          }

        } else {

          this.goodSpecstableData.forEach(el => {
            if (!el.product_price || !el.product_price.trim()) {
              flag = true
            }
            if (!el.product_bn || !el.product_bn.trim()) {

              flag2 = true
            }
          })

        }

        // 遍历看看 必填项 规格的 价格  规格 的bn
        if (flag) {
          return this.$message.warning('请填写标准单价！')
        }
        if (flag2) {
          return this.$message.warning('请填写商品码！')
        }
        if (!flag && !flag2) {
          this.step = key
        }



      }
      if (key == 4) {
        this.step = key
        delete this.form.goods_type_hz
      }
      this.step = key


    },
    //取消
    cancel() {
      let self = this
      self.$confirm({
        closable: true,
        title: '提示',
        content: '当前已填写数据尚未保存，确定取消保存吗？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          self.$router.push({ path: '/shop/management/managementlist' })
          Msg.$emit('changethirdaryShow', true)
        },
        onCancel() { },
      })

    },
    handlePreview(src, type) { },
    addTableItem(key, type) {
      if (this.form[key].some((it) => it.editable)) {
        this.$message.warning('请完成正在编辑的条目操作！')
        return
      }
      this['edit_item'] = undefined
      if (key == 'dividend_ratio_list') {
        this.form[key].splice(0, 0, {
          editable: true,
          operate: this.operate,
          dividend_business: undefined,
          dividend_rate: undefined,
          id: new Date().getTime(),
        })
      }
      if (key == 'commission_rule_no' || key == 'commission_rule_yes') {
        this.form[key].splice(0, 0, {
          editable: true,
          operate: this.operate,
          first_purchase_rate: undefined,
          payment_method: undefined,
          repurchase_rate: undefined,
          product_type: type,
          id: new Date().getTime(),
        })
      }
      console.log('this.form[key] :>> ', this.form[key]);
    },
    saveItem(record, index, key) {
      let self = this
      if (key == 'dividend_ratio_list') {
        console.log(record, index, key)
        const { dividend_rate } = record
        const specValueAntDElText = self.$refs['SpecValueRef_' + index].$el.innerText
        console.log(specValueAntDElText)
        let msg
        if (specValueAntDElText.includes('选择类型')) {
          msg = '请选择类型！'
          self.$notification['error']({
            message: '错误',
            description: msg
          })
          return
        }
        if (!dividend_rate) {
          msg = '分红比例不能为空！'
          self.$notification['error']({
            message: '错误',
            description: msg
          })
          return
        }
      }
      if (key == 'commission_rule_no' || key == 'commission_rule_yes') {
        const { first_purchase_rate, repurchase_rate } = record
        const specValueAntDElText = self.$refs['productTypeRef_' + index].$el.innerText
        console.log(specValueAntDElText)
        let msg
        if (specValueAntDElText.includes('选择类型')) {
          msg = '请选择类型！'
          self.$notification['error']({
            message: '错误',
            description: msg
          })
          return
        }
        if (!first_purchase_rate) {
          msg = '首购付款分佣比例不能为空！'
          self.$notification['error']({
            message: '错误',
            description: msg
          })
          return
        }
        if (!repurchase_rate) {
          msg = '再次付款分佣比例不能为空！'
          self.$notification['error']({
            message: '错误',
            description: msg
          })
          return
        }
      }
      setTimeout(() => {
        self.form[key][index].editable = false
      }, 400)
    },
    editItem(id, key) {
      if (this.form[key].some((it) => it.editable)) {
        this.$message.warning('请完成正在编辑的条目操作！')
        return
      }
      this.form[key] = this.form[key].map((it) => {
        if (it.id == id) {
          this['edit_item'] = it
          it.editable = true
        }
        return it
      })
    },
    cancelItem(index, key) {
      if (!this['edit_item']) {
        this.form[key].splice(index, 1)
        return
      }
      this.form[key] = this.form[key].map((it, ind) => {
        if (index == ind) {
          it.editable = false
        }
        return it
      })
    },
    deleteItem(record, index, key) {
      this.form[key].splice(index, 1)
    },
    editItemQy(record) {
      this.modal.form = record;
      this.qyStep = 1;
      this.modal.visible = true;
    },
    deleteItemQy(index) {
      let self = this
      self.$confirm({
        closable: true,
        title: '提示',
        content: '是否删除？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          self.form.benefit.splice(index, 1)
        },
        onCancel() { },
      })
    },
    // 添加权益
    handleOk() {
      if (!this.modal.form.name) return this.$message.warning('请填写权益名称！')
      if (!this.modal.form.goods_content) return this.$message.warning('请填写权益详情！')
      this.modal.form.count = this.modal.form.count_type == 1 ? 0 : this.modal.form.count;
      this.form.benefit.push(this.modal.form);
      this.modal.visible = false;
      this.qyStep = 1
    },
    handleCancel() {
      this.qyStep = 1;
      this.modal.visible = false
    },
    concatFunction(arr1, arr2) {
      //不要直接使用var arr = arr1，这样arr只是arr1的一个引用，两者的修改会互相影响  
      var arr = arr1.concat();
      //或者使用slice()复制，var arr = arr1.slice(0)  
      for (var i = 0; i < arr2.length; i++) {
        arr.indexOf(arr2[i]) === -1 ? arr.push(arr2[i]) : 0;
      }
      console.log('concatFunction', arr)
      return arr
    },
    submit() {

      if (this.form.is_all_shop == 2) {
        if (!this.withBrandCheck.length) return this.$message.warning('请选择指定门店！')
      }
      console.log('this.withBrandCheck :>> ', this.withBrandCheck);
      this.form.institution_ids = this.is_all_shop == 1 ? 'all' : this.withBrandCheck.toString();
      const { commission_rule_no, commission_rule_yes } = this.form;
      console.log('commission_rule_no :>> ', commission_rule_no);
      console.log('commission_rule_yes :>> ', commission_rule_yes);
      let arr = this.concatFunction(commission_rule_no, commission_rule_yes)
      let data = {
        ...this.form,
        commission_rule: arr,
      }
      delete data.commission_rule_no;
      delete data.commission_rule_yes;
      const hide = this.$message.loading('正在保存..', 0)
      if (this.$route.query.type == 2) {
        partnerSave(data).then((res) => {
          this.cancel()
        }).finally(r => {
          hide()
        })
      }
      if (this.$route.query.type == 1) {
        partnerAdd(data).then((res) => {
          this.cancel()
        }).finally(r => {
          hide()
        })
      }
    },
    goStep(e) {
      if (this.$route.query.type != 1) {
        this.step = e
      }
    },
    changeRoute() {
      this.$router.replace({ path: this.$route.fullpath ? this.$route.fullpath : this.$route.path });
      let newobject = storage.get('seconderyMenuObj')
      Msg.$emit('getpathname', newobject)

      this.$forceUpdate();
    },
    //  门店分类 树形列表
    // getCompanyoptionList(e) {
    //   GetgoodsoptionsList().then(res => {
    //     this.treeRecursion(res.data, 'category_id')
    //     this.treeData = res.data
    //     console.log(this.treeData, '>>>this.coptionsList');
    //     if (!e) {
    //       // this.query.company_parent = res.data[0].company_id
    //       // this.form.company_parent = res.data[0].company_id
    //       // this.getList()
    //     }

    //   })
    // },
    // //  财务分类 树形列表
    // getCompanyoptionList2(e) {
    //   TreetypeFinanceList().then(res => {
    //     this.treeRecursion(res.data, 'finance_category_id')
    //     this.treeData2 = res.data
    //     console.log(this.treeData2, '>>>this.treeData2');
    //     if (!e) {
    //       // this.query.company_parent = res.data[0].company_id
    //       // this.form.company_parent = res.data[0].company_id
    //       // this.getList()
    //     }

    //   })
    // },
    // //  商城分类 树形列表
    // getCompanyoptionList3(e) {
    //   TreetypeMallList().then(res => {
    //     this.treeRecursion(res.data, 'store_category_id')
    //     this.treeData3 = res.data
    //     console.log(this.treeData3, '>>>this.coptionsList3');
    //     if (!e) {
    //       // this.query.company_parent = res.data[0].company_id
    //       // this.form.company_parent = res.data[0].company_id
    //       // this.getList()
    //     }

    //   })
    // },
    // 递归改变id key number
    treeRecursion(data, str) {
      data.forEach(el => {
        // 把数字类型转为字符串 
        if (typeof (el[str]) == 'number') {
          el[str] = el[str].toString()
        }
        if (el.children && el.children.length) this.treeRecursion(el.children, str);
      })
    },
    getBrands() {
      GetgoodsbrandsoptionsList().then(res => {
        console.log(res, "getBrands");
        if (res.error_code == 0) {
          this.brandsList = res.data

        }
      })
    },
    openDatails() {
      let id = this.$route.query.id //this.$route.query.id
      GetGoodsdetails({ pool_goods_id: id, is_shelved: this.$route.query.is_shelved }).then(res => {
        // res =
        // {
        //   "data":
        //   {
        //     "goods_id": 7,
        //     "goods_bn": "servec1",
        //     "goods_name": "\u670d\u52a1\u540d\u79f0",
        //     "goods_desc": "\u63cf\u8ff0",
        //     "goods_image": "https:\/\/sandbox.static.meirenji.vip\/Laravel\/\/2023\/05\/18\/6465eb132b809.png", "goods_slider": "[\"https:\\\/\\\/sandbox.static.meirenji.vip\\\/Laravel\\\/\\\/2023\\\/05\\\/18\\\/6465eb1070274.png\"]",
        //     "goods_price": "333.00",
        //     "goods_cost_price": "0.00",
        //     "goods_market_price": "5555.00",
        //     "goods_type": 5,
        //     "goods_supplier": 1,
        //     "goods_brand": 24,
        //     "goods_category": 5,
        //     "store_category_id": 0,
        //     "finance_category_id": 4,
        //     "goods_unit": "盒",
        //     "goods_stock": 0,
        //     "goods_sales": 0,
        //     "is_up_price": 0,
        //     "is_give": 0,
        //     "is_show": 1,
        //     "is_del": 0,
        //     "created_at": "2023-05-18 17:08:39",
        //     "updated_at": "2023-05-18 17:09:17",
        //     "goods_content": "<p>\u670d\u52a1\u8be6\u60c5<\/p><p><br><\/p>",
        //     "goods_attr": [{
        //       "goods_attr_id": 8, "goods_id": 7, "attr_name": "\u989c\u8272", "attr_values": ["\u7ea2\u8272", "\u767d\u8272"],
        //       "created_at": "2023-05-18 17:09:11",
        //       "updated_at": "2023-05-18 17:09:11"
        //     }],
        //     "goods_attr_val": [],
        //     "category_info": { "category_id": 5, "category_parent": 4, "category_parent_path": "3,4", "category_name": "\u95e8\u5e97\u4e09\u7ea7\u5206\u7c7b1", "category_icon": "", "category_sort": 1, "category_status": 1, "created_at": "2023-05-10 21:50:39", "updated_at": "2023-05-10 23:11:11" },
        //     "store_category_info": null,
        //     "finance_category_info": {
        //       "finance_category_id": 4, "category_parent": 0, "category_parent_path": "", "category_name": "\u8d22\u52a1\u4e00\u7ea7\u5206\u7c7b\u540d\u79f02", "category_icon": "", "category_sort": 1, "category_status": 1,
        //       "created_at": "2023-05-10 16:27:52",
        //       "updated_at": "2023-05-10 16:27:52"
        //     },
        //     "is_shelved": 1
        //   }, "error_code": 0, "trace_id": "64b7f48717e5a"
        // }
        
        console.log(this.brandsList);
        this.form = Object.assign(this.form, res.data)
        let str = res.data.goods_bn
        console.log(str);
        console.log(res.data);
        this.goods_name = this.form.goods_name

        console.log(this.form.goods_bn, "goods_bn");
        this.form.goods_unit = this.form.goods_unit ? this.form.goods_unit : undefined
        this.form.goods_type = this.form.goods_type == 3 ? 1 : this.form.goods_type == 1 ? 1 : 2
        this.form.goods_brand = this.form.goods_brand ? this.form.goods_brand : undefined
        this.form.goods_supplier = this.form.goods_supplier + ''
        this.form.goods_slider = JSON.parse(this.form.goods_slider)
        this.form.goods_attr_val.forEach((el, idx) => {
          el.product_id_add = el.goods_id
        })

        // [] 
        if (this.form.goods_category) {
          this.form.goods_category = (res.data.category_info.category_parent_path + ',' + (res.data.category_info.category_id + '')).split(',')

        } else if (this.form.goods_category == 0) {
          this.form.goods_category = undefined
        }
        if (this.form.store_category_id) {
          this.form.store_category_id = (res.data.store_category_info.category_parent_path + ',' + (res.data.store_category_info.store_category_id + '')).split(',')

        } else if (this.form.store_category_id == 0) {
          this.form.store_category_id = undefined
        }
        // store_category_info
        // this.form.store_category_id = (res.data.store_category_info.category_parent_path + ',' + (res.data.store_category_info.store_category_id + '')).split(',')
        if (res.data.finance_category_id) { this.form.finance_category_id = [res.data.finance_category_id + ""] } else { this.form.finance_category_id = undefined }
        // 单规格 or 多规格
        let self = this
        if (this.form.goods_attr && this.form.goods_attr.length) {//多规格
          this.goods_specs.isSingle = 2
          this.goods_specs.new_goods_specs_list = []
          this.goods_specs.goods_specs_keys_list = []
          //  这里多规格 回显设置规格的值列表
          this.form.goods_attr.forEach((el, idx) => {
            let obj = {
              id: "p" + idx,
              attr_name: el.attr_name,
              attr_values: el.attr_values
            }

            self.goods_specs.new_goods_specs_list.push(obj)
          })

          console.log(this.goods_specs.new_goods_specs_list);

          // 还需要回显规格商品表
          this.goodSpecstableData = []

          // 处理 goods_specs_clomns
          let config_clomns = []
          // this.goods_specs_clomns = []
          let arri1 = []
          this.form.goods_attr.forEach((el, idx) => {
            let obj = {
              title: el.attr_name,
              dataIndex: el.attr_name,
              key: el.attr_name,
              ellipsis: true,
              width: 180,
              scopedSlots: { customRender: el.attr_name },
              className: 'datestyle',
            }
            let object1 = {
              attr_name: el.attr_name,
              attr_values: el.attr_values,
              product_id: el.product_id
            }
            config_clomns.push(obj)
            arri1.push(object1)
          })
          this.form.goods_attr = arri1
          console.log(this.goods_specs_clomns);

          this.goods_specs_clomns = [...config_clomns, ... this.goods_specs_clomns]
          console.log(this.goods_specs_clomns, "...this.goods_specs_clomns");
          let newResult = arri1.reduce((a, b, c) => {
            let res = []
            a.map(x => {
              b.attr_values.map(y => {
                res.push({ ...x, [b.attr_name]: y })
              })
            })
            return res
          }, [{}])
          if (this.form.goods_attr_val.length) {
            newResult.forEach((el, idx) => {
              let obj = {
                product_id: this.form.goods_attr_val[idx] ? this.form.goods_attr_val[idx].product_id : '',
                product_bn: this.form.goods_attr_val[idx].product_bn,
                product_attr: this.form.goods_attr_val[idx].product_attr,
                // product_attr: {"颜色": "白色", "量装": "瓶装", "套装": "25"}
                product_is_def: this.form.goods_attr_val[idx].product_is_def,
                product_image: this.form.goods_attr_val[idx].product_image,
                product_price: this.form.goods_attr_val[idx].product_price,
                product_market_price: this.form.goods_attr_val[idx].product_market_price,
                product_cost_price: this.form.goods_attr_val[idx].product_cost_price,
                product_market_price: this.form.goods_attr_val[idx].product_market_price,
                product_stock: this.form.goods_attr_val[idx].product_stock,
                product_sales: this.form.goods_attr_val[idx].product_sales,
                product_stock: this.form.goods_attr_val[idx].product_stock,
                consume_material: this.form.goods_attr_val[idx].consume_material
              }
              for (const i in el.product_attr) {
                el[j] = el.product_attr[i];
              }
              el = Object.assign(el, obj)
              delete el.product_attr
            })
            this.goodSpecstableData = newResult
          }

          console.log(this.goods_specs.new_goods_specs_list, "goods_specs.new_goods_specs_list");

        } else {//单规格
          this.goods_specs.isSingle = 1
          this.goodSpecstableData = this.form.goods_attr_val
        }

        let fileList = []
        // 回显轮播图
        this.form.goods_slider.forEach((val, key) => {
          fileList.push({
            uid: '-' + key,
            // name: '' + val.name, // 文件名
            status: 'done',
            url: '' + val
          })
        })

        if (this.$refs.uploadCom && this.$refs.uploadCom.fileList) {
          this.$refs.uploadCom.fileList = fileList
        }
        // 回显bn
        this.form.goods_bn = str
        // 回显品牌
        this.brandsList.forEach(el => {
          if (el.brand_id == this.form.goods_brand) {
            this.form.goods_brand = el.brand_name
          }
        })
        // 回显配送支持
        let arrdel = []
        this.form.delivery_method.forEach(el => {
          if (el == 1) {
            arrdel.push('到店核销')
          }
          if (el == 2) {
            arrdel.push('物流配送')
          }
          if (el == 3) {
            arrdel.push('同城快送')
          }
        })
        this.form.delivery_method = arrdel

      })
    },
    onChange() { },
    onChangeTimes(date, dateString) {
      this.form.goods_shou_end_time = dateString
    },
    onChange1() { },
    getPopupContainer(triggerNode) {
      console.log(triggerNode);
      let timer = setTimeout(() => {
        document.querySelectorAll('.styledatas')[0].children[1].style.width = 'auto'
        clearTimeout(timer)
        timer = ''
      })

      return document.querySelectorAll('.styledatas')[0]
    },
    getPopupContainer2(triggerNode) {
      console.log(triggerNode);
      let timer = setTimeout(() => {
        document.querySelectorAll('.styledatas2')[0].children[1].style.width = 'auto'
        clearTimeout(timer)
        timer = ''
      })

      return document.querySelectorAll('.styledatas2')[0]
    },
    getPopupContainer3(triggerNode) {
      console.log(triggerNode);
      let timer = setTimeout(() => {
        document.querySelectorAll('.styledatas3')[0].children[1].style.width = 'auto'
        clearTimeout(timer)
        timer = ''
      })

      return document.querySelectorAll('.styledatas3')[0]
    },
    // 单规格 上传规格图
    async uploadFiles(info) {
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + '/upload'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {

        this.form.goods_attr_val[0].product_image = res.data.url
      } else {
        this.$message.error(res.error.message)
      }
    },
    uploadFilesToServer(uploadApiUrl, fileName, files) {
      let formData = new FormData();
      formData.append(fileName, files);
      //添加请求头
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      //配置头
      const request = axios.create({
        headers: headers,
      });
      //开始上传
      return request
        .post(uploadApiUrl, formData)
        .then((response) => {
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    // 选择规格
    changeGoodsSpecs(e, i) {
      let self = this
      if (e == 9999) {
        this.goods_specs.goods_specs_id = undefined
        this.showaddGoodsprivatespec = true //自定义新增规格弹框
        this.ischangeGoodsSpecs1 = false
        return false
      }
      this.goods_specs.goods_specs_list.forEach(el => {
        if (el.id == e) {
          self.goods_specs.goods_specs_values_list = el.attr_values
          //  
          // self.goods_specs.new_goods_specs_list.push(el)
          this.goods_specs.goods_specs_selectobj = el
        }

      })
      this.ischangeGoodsSpecs1 = true
      console.log(this.goods_specs.goods_specs_values_list, "goods_specs_values_list");
      // console.log(self.goods_specs.goods_specs_keys_list, " self.goods_specs.goods_specs_keys_list");

    },
    // 确认规格
    changeGoodsSpecs1() {
      // 确认没有空的规格值  然后push

      let flag = false
      let self = this
      this.goods_specs.goods_specs_values_list.forEach(el => {
        if (!el) {
          flag = true
        }
      })
      if (flag && self.goods_specs.goods_specs_values_list.length > 0) {
        this.$warning({
          title: '提示',
          content: h => {
            return h('div', [
              h(
                'span',
                [' 信息尚未填写，请完善内容后进入下一步。']
              ),
            ])
          },

          okText: '好的',
          okType: 'primary',
          // cancelText: '取消',
          onOk() {
            // self.qystep = 2
          },
        });
        return false
      }
      console.log(this.goods_specs.goods_specs_values_list);
      // this.goods_specs.goods_specs_values_list.push('')
      console.log(this.goods_specs.goods_specs_selectobj);
      this.goods_specs.new_goods_specs_list.push(this.goods_specs.goods_specs_selectobj)
      this.goods_specs.goods_specs_list.forEach((el, idx) => {
        if (el.id == this.goods_specs.goods_specs_selectobj.id) {

          this.goods_specs.goods_specs_list.splice(idx, 1)
          this.goods_specs.goods_specs_id = undefined
        }
      })
      console.log(this.goods_specs.goods_specs_list);
      // 生成多规格列表
      this.hideModal()
      this.ischangeGoodsSpecs1 = false
      this.isShowGuigeAddSelect = false
    },
    // 
    onqyChange(e, it, idex) {
      let self = this
      let a = self.goods_specs.goods_specs_values_list[idex]
      console.log(e, self.goods_specs.goods_specs_values_list[idex]);
      console.log(this.goods_specs.goods_specs_values_list);

      self.goods_specs.goods_specs_values_list[idex] = e.target.innerText
    },
    closegoodsSpecsValues(e) {
      this.goods_specs.goods_specs_values_list.splice(e, 1)
      console.log(this.goods_specs.goods_specs_values_list, "this.goods_specs.goods_specs_values_list");
    },
    legalObjadd() {
      let flag = false
      let self = this
      this.goods_specs.goods_specs_values_list.forEach(el => {
        if (!el) {
          flag = true
        }
      })
      if (flag && self.goods_specs.goods_specs_values_list.length > 0) {
        this.$warning({
          title: '提示',
          content: h => {
            return h('div', [
              h(
                'span',
                [' 信息尚未填写，请完善内容后进入下一步。']
              ),
            ])
          },

          okText: '好的',
          okType: 'primary',
          // cancelText: '取消',
          onOk() {
            // self.qystep = 2
          },
        });
        return false
      }
      console.log(this.goods_specs.goods_specs_values_list);
      this.goods_specs.goods_specs_values_list.push('')
    },
    // 删除新增的规格名 规格值
    deleteNewspecs(e, it) {
      let self = this
      // delete this.goods_specs.new_goods_specs_list[e]
      this.goods_specs.new_goods_specs_list.splice(e, 1)
      // 恢复 goods_specs_list 的值
      this.goods_specs.goods_specs_list0.forEach(el => {
        if (el.id == it.id) {
          self.goods_specs.goods_specs_list.unshift(it)
        }
      })
      // 重新 生成多规格列表
      this.hideModal()
      console.log(this.goods_specs.new_goods_specs_list);
    },
    closegoodsSpecsValues(e) {
      this.goods_specs.goods_specs_values_list.splice(e, 1)
      console.log(this.goods_specs.goods_specs_values_list, "this.goods_specs.goods_specs_values_list");
    },
    deletenewGoodsSpecs(idx, n) {
      // 调取 如果

      let obj = {}
      this.goods_specs.goods_specs_list0.forEach((el, index) => {
        if (el.attr_name == this.goods_specs.new_goods_specs_list[idx].attr_name) {
          obj = el
        }
      })
      this.goods_specs.new_goods_specs_list[idx].attr_values.splice(n, 1)
      if (this.goods_specs.new_goods_specs_list[idx].attr_values.length == 0) {
        this.goods_specs.new_goods_specs_list.splice(idx, 1)
        if (obj.length) this.goods_specs.goods_specs_list.unshift(obj)
      }
      // console.log(this.goods_specs.new_goods_specs_list[idx].attr_values);
      // 选择框 加入 删除的选项

      this.hideModal()
    },
    // 批量生成规格表格  设置 单价 和 货品码
    createdSpecsTable() {
      let self = this

      self.goods_specs
      this.goodSpecstableData.forEach((el, idx) => {
        self.goodSpecstableData[idx].product_price = self.goods_specs.price
        self.goodSpecstableData[idx].product_market_price = self.goods_specs.product_market_price
        self.goodSpecstableData[idx].product_bn = self.goods_specs.barcode
        self.goodSpecstableData[idx].product_image = self.goods_specs.product_image

      })
      //  
      console.log(this.goodSpecstableData, "vvvthis.goodSpecstableData");
    },
    // 改变 某一个单价/条码
    changeSingeSpecIpt(record, i, flag) {
      console.log(record, i, flag, "val,record");
      //  
      console.log(this.goodSpecstableData, 'goodSpecstableData')
      if (flag) {
        this.goodSpecsSingletableData.forEach((el, idx) => {
          if (el.product_id == record.product_id || el.product_id_add == record.product_id_add) {
            this.goodSpecsSingletableData[idx][i] = record[i]
          }
        })
      } else {
        this.goodSpecstableData.forEach((el, idx) => {
          if (el.product_id == record.product_id || el.product_id_add == record.product_id_add) {
            this.goodSpecsSingletableData[idx][i] = record[i]
          }
        })
      }

    },

    addnewgoodsspecs(idx, n) {
      let flag = false
      let self = this
      this.goods_specs.new_goods_specs_list[idx].attr_values.forEach(el => {
        if (!el) {
          flag = true
        }
      })
      if (flag && self.goods_specs.new_goods_specs_list[idx].attr_values.length > 0) {
        this.$warning({
          title: '提示',
          content: h => {
            return h('div', [
              h(
                'span',
                [' 信息尚未填写，请完善内容后操作。']
              ),
            ])
          },

          okText: '好的',
          okType: 'primary',
          // cancelText: '取消',
          onOk() {
          },
        });
        return false
      }
      console.log(this.goods_specs.new_goods_specs_list[idx].attr_values);
      this.goods_specs.new_goods_specs_list[idx].attr_values.push('')
    },
    addnewgoodsspecs1() {
      let flag = false
      let self = this
      if (this.singelguigeadd.attr_values.length) {
        this.singelguigeadd.attr_values.forEach(el => {
          if (!el) {
            flag = true
          }
        })
        if (flag && self.singelguigeadd.attr_values.length > 0) {
          this.$warning({
            title: '提示',
            content: h => {
              return h('div', [
                h(
                  'span',
                  [' 信息尚未填写，请完善内容后操作。']
                ),
              ])
            },

            okText: '好的',
            okType: 'primary',
            // cancelText: '取消',
            onOk() {
            },
          });
          return false
        }
      } else {
        // this.singelguigeadd[idx].attr_values.push('')
      }

      console.log(this.singelguigeadd.attr_values);
      this.singelguigeadd.attr_values.push('')
    },
    addguigeokFn(e) {

      console.log(e, 'addguigeokFn');
      console.log(this.singelguigeadd, 'singelguigeadd');
      this.goods_specs.new_goods_specs_list.push(this.singelguigeadd)
      this.singelguigeadd = {
        attr_name: '',
        attr_values: [],
      }
      this.hideModal()
      this.showaddGoodsprivatespec = false
      this.isShowGuigeAddSelect = false
    },
    // 生成多规格table
    hideModal() {
      console.log('往表格里面加规格列表');

      var arr = []
      var arr_goods_attr = []
      this.goods_specs.new_goods_specs_list
      this.goods_specs.goods_specs_list
      //  
      arr = [...this.goods_specs.new_goods_specs_list]
      // todo 以后这里需要把规格列表传给后台 成功之后 1，2
      // 1 在这里就需要渲染table的数据和columns操作
      let arri1 = []
      // coluns
      let columns = []
      let specs_clomns = [
        {
          title: '规格图',
          dataIndex: 'product_image',
          key: 'product_image',
          className: 'pdl-24',
          ellipsis: true,
          scopedSlots: { customRender: 'product_image' },
        },
        {
          // title: '*标准单价',
          slots: { title: 'product_price' },
          dataIndex: 'product_price',
          key: 'product_price',
          ellipsis: true,
          scopedSlots: { customRender: 'product_price' },
        },
        {
          // title: '*货品码',
          slots: { title: 'product_bn' },
          dataIndex: 'product_bn',
          key: 'product_bn',
          ellipsis: true,
          scopedSlots: { customRender: 'product_bn' },
        },
        {
          title: '市场价',
          dataIndex: 'product_market_price',
          key: 'product_market_price',
          ellipsis: true,
          scopedSlots: { customRender: 'product_market_price' },
        },
      ]
      arr.forEach((el, index) => {
        let object1 = {
          attr_name: el.attr_name,
          attr_values: el.attr_values,
        }
        let object2 = {
          title: el.attr_name,
          dataIndex: el.attr_name,
          key: el.attr_name,
          ellipsis: true,
          width: 180,
          scopedSlots: { customRender: el.attr_name },
          className: 'datestyle',
        }
        arri1.push(object1)
        columns.push(object2)
      })
      this.form.goods_attr = arri1 //规格
      // 动态生成表头
      this.goods_specs_clomns = [...columns, ...specs_clomns]

      // 
      let newResult2 = []
      let newResult = arri1.reduce((a, b, c) => {
        let res = []
        a.map(x => {
          b.attr_values.map(y => {
            res.push({ ...x, [b.attr_name]: y })
          })
        })
        return res
      }, [{}])
      // newResult2 = Object.assign(newResult2, newResult)
      console.log(newResult, 1, newResult2)
      // 动态生成 商品规格表

      // 编辑条件下 比较与旧版的商品规格表 把数据贴上去
      if (this.addorupdateSpecFlag == 'update') {
        newResult.forEach((el, idx) => {
          this.form.goods_attr_val.forEach((m, n) => {
            //  
            let reg = /\\"/g;
            let a = JSON.stringify(m.product_attr).replace(reg, '');
            console.log(JSON.stringify(el) == a)
            if (JSON.stringify(el) == a) {
              newResult[idx] = Object.assign(m, el)
              // newResult[idx] = m
            }

          })

        })

        newResult.forEach((el, idx) => {
          let obj = {
            product_id_add: idx,
            product_bn: '',
            product_attr: '',
            product_is_def: 1,
            product_image: '',
            product_price: '',
            product_cost_price: '',
            product_market_price: '',
            product_stock: ''
          }
          let a = { ...obj, ...el }
          newResult2.push(a)
        })
        console.log(newResult, 2)

        this.goodSpecstableData = newResult2
      } else {

        newResult.forEach((el, idx) => {
          let obj = {
            product_id_add: idx,
            product_bn: '',
            product_attr: '',
            product_is_def: 1,
            product_image: '',
            product_price: '',
            product_cost_price: '',
            product_market_price: '',
            product_stock: ''
          }
          let a = { ...obj, ...el }
          newResult2.push(a)
        })
        this.goodSpecstableData = newResult2
      }

      this.goods_specs.ischooseModehow = false

    },
    hideModal2(new_goods_specs_list) {

      var arr = []
      var arr_goods_attr = []
      this.goods_specs.new_goods_specs_list = new_goods_specs_list
      this.goods_specs.goods_specs_list

      arr = [...this.goods_specs.new_goods_specs_list]
      // todo 以后这里需要把规格列表传给后台 成功之后 1，2
      // 1 在这里就需要渲染table的数据和columns操作
      let arri1 = []
      let columns = []
      let specs_clomns = [
        {
          title: '规格图',
          dataIndex: 'product_image', className: 'pdl-24',
          key: 'product_image',
          ellipsis: true,
          scopedSlots: { customRender: 'product_image' },
        },
        {
          // title: '*标准单价',
          slots: { title: 'product_price' },
          dataIndex: 'product_price',
          key: 'product_price',
          ellipsis: true,
          scopedSlots: { customRender: 'product_price' },
        },
        {
          // title: '*货品码',
          slots: { title: 'product_bn' },
          dataIndex: 'product_bn',
          key: 'product_bn',
          ellipsis: true,
          scopedSlots: { customRender: 'product_bn' },
        },
        {
          title: '市场价',
          dataIndex: 'product_market_price',
          key: 'product_market_price',
          ellipsis: true,
          scopedSlots: { customRender: 'product_market_price' },
        },
      ]

      console.log(arr);
      // let object1 = {
      //   attr_name: arr[0].attr_name,
      //   attr_values: arr[0].attr_values,
      // }
      // let object2 = {
      //   title: arr[0].attr_name,
      //   dataIndex: arr[0].attr_name,
      //   key: arr[0].attr_name,
      //   ellipsis: true,
      //   scopedSlots: { customRender: arr[0].attr_name },
      // }
      // arri1 = object1
      // columns = object2 
      arr.forEach((el, index) => {
        let object1 = {
          attr_name: el.attr_name,
          attr_values: el.attr_values,
        }
        let object2 = {
          title: el.attr_name,
          dataIndex: el.attr_name,
          key: el.attr_name,
          ellipsis: true,
          width: 180,
          scopedSlots: { customRender: el.attr_name },
          className: 'datestyle',
        }
        arri1.push(object1)
        columns.push(object2)
      })
      this.form.goods_attr = arri1 //规格   
      // 动态生成表头
      this.goods_specs_clomns = [...columns, ...specs_clomns]

      let newResult2 = []
      // product_attr

      let newResult = arri1.reduce((a, b, c) => {
        let res = []
        a.map(x => {
          b.attr_values.map(y => {
            res.push({ ...x, [b.attr_name]: y })
          })
        })
        return res
      }, [{}])
      console.log(newResult, 1, newResult2)
      // 编辑条件下 比较与旧版的商品规格表 把数据贴上去
      if (this.addorupdateSpecFlag == 'update' && false) {
        newResult.forEach((el, idx) => {
          this.form.goods_attr_val.forEach((m, n) => {
            //  
            let reg = /\\"/g;
            let a = JSON.stringify(m.product_attr).replace(reg, '');
            console.log(JSON.stringify(el) == a)
            if (JSON.stringify(el) == a) {
              newResult[idx] = Object.assign(m, el)
            }
          })

        })

        newResult.forEach((el, idx) => {
          let obj = {
            product_id_add: idx,
            product_bn: '',
            product_attr: '',
            product_is_def: 1,
            product_image: '',
            product_price: '',
            product_cost_price: '',
            product_market_price: '',
            product_stock: ''
          }
          let a = { ...obj, ...el }
          newResult2.push(a)
        })
        console.log(newResult, 2)

        this.goodSpecstableData = newResult2
      } else {
        newResult.forEach((el, idx) => {
          let obj = {
            product_id_add: idx,
            product_bn: '',
            product_attr: {},
            //  "颜色": "白色", "大小": "黑色"
            product_is_def: 1,
            product_image: '',
            product_price: '',
            product_cost_price: '',
            product_market_price: '',
            product_stock: ''
          }
          let a = { ...obj, ...el }
          newResult2.push(a)
        })
        this.goodSpecstableData = newResult2
        this.form.goods_attr_val = newResult2
      }

      // 2
      this.goods_specs.ischooseModehow = false

    },
    // 批量 规格图
    async batchSetuploadspecFiles(info, record, flag) {
      // console.log(info)
      // console.log(record); 
      // console.log(this.goodSpecstableData);
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + '/upload'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {
        this.goods_specs.product_image = res.data.url
      } else {
        this.$message.error(res.error.message)
      }
    },
    // 列表 规格图
    // 规格图
    async uploadspecFiles(info, record, flag) {
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + '/upload'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {
        if (flag) {
          this.goodSpecsSingletableData[0].product_image = res.data.url
        } else {
          this.goodSpecstableData.forEach(el => {

            if (record.product_id >= 0) {
              if (el.product_id == record.product_id) {
                el.product_image = res.data.url
              }
            }
            if (record.product_id_add >= 0) {
              if (el.product_id_add == record.product_id_add) {
                el.product_image = res.data.url
              }
            }

          })
        }


        // this.form.goods_image = res.data.url
        console.log(this.goodSpecstableData);
      } else {
        this.$message.error(res.error.message)
      }
    },
    // 轮播图
    getDelImageId(val) {
      if (this.form.goods_slider && this.form.goods_slider.length > 0) {

      } else {
        this.form.goods_slider = []
      }
      this.form.goods_slider.push(val)
    },
    getImageshow(val) {
      this.pulsshow = val
    },
    delDelimageId(index) {
      this.form.goods_slider.forEach((val, key) => {
        if (index === key) {
          this.form.goods_slider.splice(key, 1)
        }
      })
    },
    // 获取到重新排序后的图片
    handleDraggable(e) {
      const imgDrag = []
      for (var i = 0; i < e.length; i++) {
        // var a = e[i].url.split('/')
        imgDrag.push(e[i].url)
      }
      this.form.goods_slider = imgDrag
    },
    // 商品 f封面图
    async ffuploadFiles(info) {
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + '/upload'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {
        this.form.goods_image = res.data.url
      } else {
        this.$message.error(res.error.message)
      }
    },
    // 提交按钮
    okclickmod(e) {

      function fn(beforeData, addorupdateSpecFlag) {
        var afterData = []
        beforeData.forEach(item => {
          let flag
          if (addorupdateSpecFlag == 'add') {
            flag = afterData.find(item1 => item1.product_id && item.product_id && item1.product_id == item.product_id)
          } else {
            flag = afterData.find(item1 => item1.product_id_add == item.product_id_add)
          }

          if (!flag) {
            if (addorupdateSpecFlag == 'add') {
              afterData.push({
                product_id: item.product_id,
                product_attr: [item]
              })
            } else {
              afterData.push({
                product_id_add: item.product_id_add,
                product_attr: [item]
              })
            }

          } else {
            flag.product_attr.push(item)
          }
        })
        console.log(afterData, 'afterDataafterData');
        return afterData

      }
      function chkstrlen(str) {
        var strlen = false;
        for (var i = 0; i < str.length; i++) {
          if (str.charCodeAt(i) > 255) //如果是汉字， 
            strlen = true;
        }
        return strlen;
      }

      let data_pa = {
        ...this.form
      }
      // 这里要搞事情 goods_specs.isSingle  生成多规格商品列表 goodSpecstableData
      if (this.goods_specs.isSingle != 1) {
        // 多规格
        this.form.goods_attr_val = this.goodSpecstableData
        let arry = []
        this.form.goods_attr_val.forEach(el => {
          this.form.goods_attr.forEach((m, n) => {
            let obj = {}
            if (el.hasOwnProperty(m.attr_name)) {
              //  
              obj[m.attr_name] = el[m.attr_name]
              obj.product_id = el.product_id
              obj.product_id_add = el.product_id_add
              arry.push(obj)
            }
          })
        })
        console.log(arry, "mainarr....");
        let mainarr = fn(arry, this.addorupdateSpecFlag)
        console.log(mainarr, '/.//mainarr2');

        console.log(mainarr, "mainarr.3...");
        console.log(this.form.goods_attr_val, 'this.form.goods_attr_val');
        this.form.goods_attr_val.forEach(el => {
          el.product_attr = []
          mainarr.forEach((m, n) => {
            m.product_attr.forEach(j => {
              if (el.product_id > -1) {
                if (el.product_id == j.product_id) {
                  delete j.product_id
                  delete j.product_id_add
                  el.product_attr.push(j)
                }

              }
              if (el.product_id_add > -1) {
                if (el.product_id_add == j.product_id_add) {
                  delete j.product_id
                  delete j.product_id_add
                  el.product_attr.push(j)
                }
              }
            })


          })
        })
        // 

        console.log(this.form.goods_attr_val, " this.form.goods_attr_val");
        this.form.goods_attr_val.forEach((el, idx) => {
          if (el.product_attr.length > 1) {
            let s = JSON.stringify(el.product_attr)
            el.product_attr = JSON.parse(s.replace(/},{/g, ","))[0]
          } else if (el.product_attr.length == 1) {
            el.product_attr = el.product_attr[0]
          } else {
            el.product_attr = []
          }
          delete el.product_id
          delete el.product_id_add
          delete el.product_attr['"product_id"']
          delete el.product_attr['"product_id_add"']
          delete el.product_attr["product_id_add"]
          delete el.product_attr["product_id"]
          delete el.product_attr['"created_at"']
          delete el.product_attr['"updated_at"']
          delete el.created_at
          delete el.updated_at

          for (var pl in el) { //数组对象遍历
            console.log(pl); //获取key
            console.log(el[pl]) //获取key的值
            let flag = chkstrlen(pl) //判断是否中文字符
            if (flag) {
              delete el[pl]
            }
          }
        })
        console.log(this.form.goods_attr_val);

      } else {
        // 单规格 
        this.form.goods_attr = undefined
        // this.form.goods_attr_val.forEach((el, idx) => {
        //   el.product_attr = []
        // })
        let obj = {
          product_image: this.form.goods_attr_val[0].product_image,
          product_price: this.form.goods_attr_val[0].product_price,
          product_bn: this.form.goods_attr_val[0].product_bn,
          product_market_price: this.form.goods_attr_val[0].product_market_price
        }
        this.form.goods_attr_val = [obj]
      }
      data_pa = {
        ...this.form
      }
      if (this.form.finance_category_id && this.form.finance_category_id.length) {
        data_pa.finance_category_id = this.form.finance_category_id.length ? this.form.finance_category_id[0] : ''
      }
      if (this.form.goods_category && this.form.goods_category.length) {
        data_pa.goods_category = this.form.goods_category.length ? this.form.goods_category.pop() + '' : undefined
      } else {
        data_pa.goods_category = 0
      }
      if (this.form.store_category_id && this.form.store_category_id.length) {
        data_pa.store_category_id = this.form.store_category_id.length ? this.form.store_category_id.pop() + '' : undefined
      } else {
        data_pa.store_category_id = 0
      }
      delete data_pa.pic_url_loading
      // 区分新增 修改接口
      if (this.$route.query.type == 3) {
        if (data_pa.category_info) delete data_pa.category_info
        if (data_pa.store_category_info) delete data_pa.store_category_info
        if (data_pa.created_at) delete data_pa.created_at
        if (data_pa.updated_at) delete data_pa.updated_at
        if (data_pa.finance_category_info) delete data_pa.finance_category_info

        UpdateGoods(data_pa).then(res => {
          if (res.error_code == 0) {
            this.$message.success('修改成功')
            this.changeRoute()
          }
        })
      } else {
        //新增 
        delete data_pa.goods_id
        delete data_pa.product_id
        delete data_pa.product_id_add
        delete data_pa.goods_type_hz
        CreateGoods(data_pa).then(res => {

          if (res.error_code == 0) {
            this.$message.success('创建成功')
            // this.getList()
            this.changeRoute()
          }
        }).catch(err => {
          console.log(err);
        })
      }
    },
    changeGglable(e) {
      // if (e.target.value == 1  && this.form.goods_attr.length > 0) {
      if (e.target.value == 1 && this.$route.query.type == 3) {
        this.goods_specs.isSingle = 2
        let self = this
        this.$confirm({
          icon: 'exclamation-circle',
          closable: true,
          title: '提示',
          content: '切换规格模式，将自动下架各渠道的全部规格，需重新上架至各渠道，请谨慎操作！',
          okText: '确定',
          okType: 'primary',
          cancelText: '取消',
          onOk() {
            self.$confirm({
              icon: 'exclamation-circle',
              closable: true,
              title: '提示',
              content: '  规格模式已切换至【单规格】模式，各渠道已下架当前商品，请重新上架新规格至各销售渠道；',
              okText: '确定',
              okType: 'primary',
              cancelText: '取消',
              onOk() {

                self.goods_specs.isSingle = 1
                self.form.goods_attr = undefined
                self.form.goods_attr_val = [{ product_image: '', product_price: '', product_bn: "" }]

              },
              onCancel() { },
            })
          },
          onCancel() { },
        })
      }
    },
    getAutowidth() {
      let wid = document.body.clientWidth
      if (wid > 1680 && this.goods_specs_clomns.length > 6) {
        return 1500
      }
      return 1300
    }

  },
  computed: {
    goods_name: {
      get() {
        return this.form.goods_name
      },
      set(val) {
        this.form.goods_name = val
        this.form.goods_bn = ''
        let that = this
        let a = pinyin(val, { toneType: 'none' })
        let b = a.split(' ')
        let arr = []
        b.forEach(el => {
          arr.push(el.slice(0, 1))
        })
        arr = arr.join('')
        this.form.goods_bn = arr
      },
    },
  }
};
</script>
<style lang="less" scoped>
.add {
  display: flex;
  font-family: PingFangSC-Medium, PingFang SC;
  padding: 14px 18px 13px 13px;
  flex: 1;
  position: relative;

  /deep/ .ant-table-footer {
    background: @gray-border-background;
  }
}
.main-ctn-2 {
  padding: 30px 0 0 35px;
  margin: 12px 0 12px 12px;
  width: 100%;
  height: calc(100vh - 157px);
  background: #fff;
  overflow-y: auto;
}
.wh30Img {
  width: 42px;
  height: 32px;
  margin-right: 10px;
  box-shadow: 0px 2px 4px 0px rgba(204, 209, 228, 0.35);
  border-radius: 2px;
  border: 1px solid #a3a8ca;
}
.twoh3 {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  .tpointer {
    display: inline-block;
    width: 4px;
    height: 4px;
    background: @primaryColor;
    border-radius: 1px;
    margin-right: 5px;
  }
  .ttxt {
    height: 18px;
    font-size: 14px;
    font-weight: 500;
    color: @fontColor1;
    margin-right: 8px;
  }
}
.desc_css {
  max-height: 64vh;
  overflow: auto;
}
.item-r-l {
  height: 22px;
  font-size: 14px;
  font-weight: 400;
  height: 22px;
  line-height: 22px;
  margin-bottom: 15px;
  margin-top: 15px;
  display: flex;
  .rText {
    color: @fontColor1;
  }
  .lText {
    color: rgba(45, 56, 53, 0.8);
    width: 123px;
    display: block;
  }
}
.history-top {
  text-align: center;
  height: 31px;
  line-height: 31px;
  background: #fff0e8;
  border: 1px solid #ffd8c3;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #f47c5b;
  .text {
    font-size: 12px;
    color: #b7978e;
  }
}
.total_balance {
  display: flex;
  align-items: center;
  background: #f3f9f8;
  border-radius: 4px;
  font-size: 22px;
  padding-left: 24px;
  height: 44px;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: @baseColor11;
  .span {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #323f3e;
  }
}
.twoh2 {
  display: flex;
  margin-bottom: 0px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: @fontColor4;
  .tpointer {
    display: inline-block;
    width: 3px;
    height: 10px;
    background: @primaryColor;
    border-radius: 1px;
    margin: 5px 5px 0 0;
  }
  .ttxt {
    margin-right: 8px;
    // height: 22px;
    // font-size: 14px;
    // font-weight: 500;
    // color: @fontColor1;
    // line-height: 22px;
  }
  .tag-sm {
    height: 22px;
    background: #e6f5f4;
    border-radius: 11px;
    padding: 0 6px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(45, 56, 53, 0.8);
    line-height: 22px;
  }
  .onsales {
    display: inline-block;
    width: 40px;
    height: 22px;
    background: #ecffeb;
    border-radius: 4px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #217f13;
    line-height: 22px;
    text-align: center;
  }
  .offsales {
    width: 40px;
    height: 22px;
    background: rgba(86, 87, 102, 0.15);
    border-radius: 4px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: @fontColor3;
    line-height: 22px;
    text-align: center;
  }
}
.shopCodes {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: @fontColor4;
  padding-left: 9px;
  margin: 4px 0 20px 0;
}
.twoh1 {
  display: flex;
  margin-bottom: 12px;
  align-items: center;
  .tpointer {
    display: inline-block;
    width: 3px;
    height: 10px;
    background: @primaryColor;
    border-radius: 1px;
    margin-right: 5px;
  }
  .ttxt {
    height: 22px;
    font-size: 14px;
    font-weight: 500;
    color: @fontColor1;
    margin-right: 8px;
  }
}

.y-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.detail-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .img {
    width: 88px;
    height: 88px;
    background: #cbe3e1;
    border-radius: 8px;
    margin-right: 18px;
  }
  .title {
    font-size: 18px;
    font-weight: 600;
  }
  .text {
    font-size: 14px;
    font-weight: 400;
    color: #323f3e;
  }
  .text-sm {
    font-size: 14px;
    font-weight: 400;
    color: #778581;
  }
}
.card-bg {
  height: 150px;
  background: #f3f9f8;
  border-radius: 4px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  .title {
    height: 22px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #323f3e;
    line-height: 22px;
    margin-bottom: 15px;
    margin-left: 15px;
  }
  .money {
    height: 30px;
    font-size: 26px;
    font-family: DINAlternate-Bold, DINAlternate;
    font-weight: bold;
    line-height: 30px;
  }
}
.shortcut-menu {
  width: 130px;
  :deep(.ant-anchor-wrapper) {
    background-color: transparent;
  }
}
/deep/.ant-table-middle
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-body
  > table
  > .ant-table-thead
  > tr
  > th,
/deep/.ant-table-middle
  > .ant-table-content
  > .ant-table-body
  > table
  > .ant-table-thead
  > tr
  > th {
  padding: 1px 8px;
}
/deep/.detailsTable .ant-table-row > td:nth-child(1),
/deep/.detailsTable .ant-table-thead > tr:first-child > th:first-child {
  padding-left: 24px !important;
}
.row-body {
  .top {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    .text-title {
      margin-left: 10px;
      font-size: 16px;
      font-weight: 400;
      color: @btnColorfont1;
    }
    .yuan-blue {
      width: 38px;
      height: 38px;
      text-align: center;
      line-height: 38px;
      color: #ffffff;
      border-radius: 100%;
      background: @primaryColor;
      border: 1px solid @dropdownBs5a;
    }
    .dian {
      color: #e5e8e5;
      margin: 0 10px;
    }
    .yuan {
      width: 38px;
      height: 38px;
      text-align: center;
      line-height: 38px;
      color: @fontColor7;
      border-radius: 100%;
      background: #fdfdff;
      border: 1px solid @btnColorshodew;
    }
  }
}
.goods_editor {
  width: 600px;
  height: 259px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #cdd5d8;
  overflow: hidden;
  overflow-y: auto;
  pointer-events: none;
}
.font-22 {
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: @fontColor1;
}
// 面包屑相关
.mrj-layout-tabs-custom {
  border-bottom: 1px solid @breadcrumbdownlineColor;
}
.leftarrow {
  color: @breadcrumbbackiconColor !important;
  border: 1px solid @breadcrumbbackborderColor !important;
  outline: none;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  text-align: center;
  box-sizing: border-box;
  line-height: 10px;
  margin-top: 1px;
  padding-top: 1.2px;
  &:hover {
    color: @breadcrumbbackiconHColor !important;
    border: 1px solid @breadcrumbbackborderHColor!important;
    outline: none;
  }
  .arrleft {
    margin-top: -1px;
    &:hover {
      color: @baseColor70 !important;
    }
  }
  .meiye-fanghui {
    font-size: 14px;
    margin-left: -1px;
  }
}
.leftarrow_hover {
  color: @baseColor70 !important;
  border: 1px solid !important;
  outline: none;
}
.mrj-layout-tabs-custom-index span {
  color: @baseColor11;
  font-size: 14px;
}
.mrj-layout-tabs-custom-index:hover .anticon {
  color: @baseColor70 !important;
  border: 1px solid !important;
  outline: none;
}
.mrj-layout-tabs-custom-fenge {
  color: @breadcrumblineColor;
}
.mrj-layout-tabs-custom-inner-page {
  color: @fontColor4;
  font-size: 14px;
}
.samerow {
  width: 100%;
  /deep/.ant-form-item {
    display: flex;
    align-items: center;
    width: 100%;
  }
  /deep/ .ant-cascader-picker-label {
    display: inline-block;
  }
}
.samerow1 {
  /deep/ .ant-form-item-children {
    display: flex;
    align-items: center;
    width: 60%;
    justify-content: space-between;
  }
}

.samerow2 {
  /deep/.ant-form-item-label-left {
    margin-left: 68px;
  }
}
#styledatas {
  div {
    width: auto !important;
  }
}
// 单选另一种框
.checkqystatus {
  width: 180px;
  height: 32px;
  line-height: 32px;
  background: @labelDbgColor;
  // box-shadow: inset 0px 3px 0px 0px @labelDinsertColor;
  border-radius: 4px;
  border: 1px solid @labelDborderColor;
  box-sizing: border-box;
  padding: 3px;
  font-size: 16px;
  .cqt1 {
    width: 90px;
    height: 28px;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    color: @labelDfontColor;
    cursor: pointer;
    border: 1px solid transparent;
    text-align: center;
    line-height: 26px;
    &:hover {
      color: @labelHfontColor;
    }
  }
  .cqt1A {
    width: 90px;
    height: 28px;
    background: @labelAbgColor;
    box-shadow: inset 0px -2px 0px 0px @labelAinsertColor;
    // box-shadow: inset 0px -2px 0px 0px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    border: 1px solid @labelAborderColor;
    color: @labelAfontColor;
    text-align: center;
    line-height: 26px;
  }
}
//单规格
.sigleguige {
  .tabelHeader {
    // width: 1605px;
    width: 100%;
    height: 48px;
    line-height: 48px;
    background: rgba(241, 246, 245, 0.6);
    border-radius: 4px;
    position: relative;
    padding-left: 25px;
    display: flex;
    align-items: center;
    &::after {
      content: " ";
      width: 100%;
      height: 2px;
      background: #e8edec;
      position: absolute;
      top: -4px;
      left: 0;
    }
    .tabelHeader_items {
      width: 188px;
      text-align: left;
      display: inline-block;
    }
    .tabelHeader_items-278 {
      width: 278px;
    }
  }
  .tableBody {
    width: 100%;
    height: 72px;
    display: flex;
    align-items: center;
    padding-left: 25px;
    border-bottom: 1px solid #e6eded;
    .tabelBody_items {
      width: 188px;
      text-align: left;
    }
    .tabelBody_items-278 {
      width: 278px;
    }
  }
}
.two {
  /deep/ .avatar-uploader,
  /deep/ .avatar-uploader .ant-upload {
    width: 48px !important;
    height: 48px !important;
    // padding-top: 4px;
  }
}

/deep/.two .avatar-uploader {
  width: 48px !important;
  height: 48px !important;
  padding-top: 2px;
  margin: 0 !important;
}
/deep/.two .ant-upload.ant-upload-select-picture-card {
  margin: 0 !important;
}
/deep/.uploaderguige .ant-upload-picture-card-wrapper .ant-upload {
  width: 48px !important;
  height: 48px !important;
  padding: 0;
  margin: 0;
}
.baseColor11 {
  color: @baseColor11 !important;
}
.htmlboxshops {
  &:focus-visible {
    border: none !important;
  }
}
.addGuigeRules {
  margin-top: 32px;
  font-size: 16px;
  font-weight: 400;
  color: #2d3835;
  i {
    color: #2d3835;
  }
}
.guigeline {
  width: 2px;
  height: 31px;
  background: #e8edec;
  margin: 0 0 0 48px;
}
.batchSet {
  height: 73px;
  background: #f1fafa;
  line-height: 73px;
  font-size: 14px;
  font-weight: 400;
  color: @fontColor4;
  .batchSet_h2 {
    font-size: 14px;
    font-weight: 500;
    color: @fontColor4;
    margin: 0 52px 0 10px;
  }
  .guigeImg {
    height: 73px;
    line-height: 73px;
    flex-wrap: nowrap;
    .guigeImg_txt {
      font-size: 14px;
      display: inline-block;
      font-weight: 400;
      color: @fontColor4;
      height: 73px;
      line-height: 73px;
    }
    /deep/ .ant-upload-picture-card-wrapper .ant-upload {
      margin: 0;
      width: 48px !important;
      height: 48px !important;
      border-radius: 4px;
      // border: 1px dashed #9ba7a7;
    }
    /deep/.ant-upload.ant-upload-select-picture-card:hover {
      border-color: @primaryColor;
    }
  }
}
.singlePrice {
}
.guigeTablebox {
  margin-top: 8px;
}
.goods_specx_add {
  position: relative;
  .guigeline {
    position: absolute;
    top: 0;
    right: 0;
  }
}
/deep/ .avatar-uploader2.ant-upload-picture-card-wrapper .ant-upload {
  width: 66px !important;
  height: 66px !important;
  // border: 1px solid #d9d9d9;
  background: #fff;
}

.boldimgspan {
  position: relative;
  .meiye-close-boldimg {
    position: absolute;
    top: -21px;
    right: -8px;
    font-size: 12px;
  }
}
.addBox .add .mgb-32 {
  margin-bottom: 32px;
}

// 多规格样式修改
/deep/.guigeTablebox .ant-table .ant-table-body .datestyle {
  text-align: center;
  border-right: 1px solid @listbodylineColor !important;
}

/deep/.guigeTablebox .ant-table .pdl-24 {
  padding-left: 6% !important;
}
/deep/.guigeTablebox .ant-table .ant-table-thead .pdl-24 {
  padding-left: 6% !important;
}
/deep/.selectGg .ant-select-dropdown-menu {
  padding-bottom: 0 in !important;
}
/deep/.mgb-18.ant-form-item {
  margin-bottom: 18px;
}
.wid-132 {
  width: 132px;
}
/deep/.samerow .ant-cascader-picker-label {
  // display: none;
  box-shadow: none;
}
.pladd {
  color: rgba(45, 56, 53, 0.4);
}
.add .addright .last-btns-step2 {
  height: 68px;
  width: calc(100vw - 317px);
  position: fixed;
  bottom: 19px;
  padding-top: 16px;
  background: #fff;
  border-top: 1px solid #d8eceb;
  z-index: 2;
  padding-left: 40px;
}
.mgl-28 {
  margin-left: 28px;
}
.chooseSku {
  display: block;
}
.mgr-20 {
  margin-right: 40px;
}
.mgt-20 {
  margin-top: 20px;
}
.mgb-20 {
  margin-bottom: 20px;
}
.mgt-16 {
  margin-top: 16px;
}
.mgb-30 {
  margin-bottom: 30px;
}
.mgb-42 {
  margin-bottom: 42px;
}
.pdt-5 {
  padding-top: 5px;
}
.leftBabletxt {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  color: @fontColor3;
  width: 123px;
  text-align: left;
}
.rightBabletxt {
  display: inline-block;
  width: 262px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: @fontColor4;
}
.swichers {
  width: 60px;
  height: 60px;
  margin-right: 12px;
  img {
    width: 60px;
    height: 60px;
  }
}

.c-box {
  p {
    margin: 0;
    padding: 0;
  }
  .sp_p1 {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: @fontColor4;
  }
  .sp_p2 {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: @fontColor3;
  }
  .sp_line {
    display: inline-block;
    width: 1px;
    height: 8px;
    background: #dde8e7;
    margin: 0 8px 0 15px;
  }
}
.consumeBox {
  // width: 206px;
  // overflow: hidden;
  // flex-wrap: wrap;
  width: fit-content;
  .c-box {
    // width: 206px;
    text-align: left;
    flex-wrap: nowrap;
    display: flex;
    height: 40px;
    // overflow: hidden;
    width: fit-content;
  }
  .consumeBox_item {
    width: 170px;
    height: 38px;
    cursor: pointer;
    display: inline-block;
  }
}
</style>