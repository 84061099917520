<template>
  <div class="addBox">
    <!--  面包屑  -->
    <div
      class="mrj-layout-tabs-custom flex align-item-center"
      style="z-index:1;"
    >
      <div
        @click="changeRoute"
        class="mrj-layout-tabs-custom-index flex align-item-center cursor-pointer"
      >
        <div
          type="left"
          class="leftarrow"
          :class="imgflag?'leftarrow_hover':''"
          @mouseenter="imgflag=true"
          @mouseleave="imgflag=false"
        >
          <i class="meiye-icon meiye-fanghui"></i>
        </div>

        <span
          @mouseenter="imgflag=true"
          @mouseleave="imgflag=false"
        >{{ $route.meta.title }}</span>
      </div>
      <span class="mrj-layout-tabs-custom-fenge">/</span>
      <span class="mrj-layout-tabs-custom-inner-page"> 查看 </span>
    </div>
    <!-- 内容区 -->
    <div class="add">
      <!-- 方案 -->
      <TagsSider
        :tabs="shoptabs"
        v-model="activeKey"
      />
      <a-layout-content
        class="main-ctn-2"
        id="mrj-scroll-main"
        @scroll="handleWrapScroll"
      >
        <!-- 基本信息 -->
        <div
          id="product-base"
          data-anchor="product-base"
        >
          <h2 class="twoh2">
            <span class="tpointer"></span>
            <span class="ttxt"> {{form.goods_name}} </span>
            <span :class="form.is_show==1?'onsales':'offsales'">{{form.is_show==1?'在售':'停售'}}</span>
          </h2>
          <h4 class="shopCodes">商品码：{{ form.goods_bn }}</h4>
          <a-row class="mgb-30 mgl-12">
            <span class="leftBabletxt">来源</span>
            <span class="rightBabletxt">总部创建</span>
            <span class="leftBabletxt">商品别名</span>
            <span class="rightBabletxt"> {{form.goods_alias_name || '--'}}</span>
          </a-row>
          <a-row class="mgb-30 mgl-12">
            <span class="leftBabletxt">商品售价</span>
            <span class="rightBabletxt baseColor11">¥{{form.goods_price}}</span>
            <span class="leftBabletxt">有效期</span>
            <span class="rightBabletxt">{{form.proposal_validity_type==1?'永久':form.proposal_validity}}</span>
          </a-row>
          <a-row class="mgb-30 mgl-12">
            <span class="leftBabletxt">品牌</span>
            <span class="rightBabletxt"> {{form.goods_brand}}</span>
            <span class="leftBabletxt">商城分类</span>
            <span class="rightBabletxt">{{form.store_category_name||'--'}}</span>
          </a-row>
          <a-row class="mgb-30 mgl-12">
            <span class="leftBabletxt">合作项目</span>
            <span class="rightBabletxt">{{(form.goods_type==1||form.goods_type==2)?'否':'是'}}</span>
            <span class="leftBabletxt">激活方式</span>
            <span class="rightBabletxt">{{form.proposal_charging_type==1?'购买之日激活':'首次兑付激活'}}</span>
          </a-row>
          <div class="cardsbox flex align-item-center">
            <div class="cardbox1">
              <p class="cr1_p1">兑付次数(次)</p>
              <p class="cr1_p2">{{form.proposal_use_type==0? '不限':form.proposal_use_count}}</p>
            </div>
            <div class="cardbox1">
              <p class="cr1_p1">实耗成本(次)</p>
              <p class="cr1_p2">{{form.proposal_count_price}}</p>
            </div>
            <div class="cardbox1">
              <p class="cr1_p1">每月兑付(次)</p>
              <p class="cr1_p2">{{form.proposal_month_use_type==0?'不限':form.proposal_month_use_count}}</p>
            </div>
            <div class="cardbox1">
              <p class="cr1_p1">每周兑付(次)</p>
              <p class="cr1_p2">{{form.proposal_week_use_type==0?'不限':form.proposal_week_use_count}}</p>
            </div>
            <div class="cardbox1">
              <p class="cr1_p1">单次兑付(次)</p>
              <p class="cr1_p2">{{form.proposal_every_sku_type==0?'不限':form.proposal_every_sku_count}}</p>
            </div>
            <div class="cardbox1">
              <p class="cr1_p1">是否限购(次)</p>
              <p class="cr1_p2">{{form.proposal_pay_type==0?'不限':form.proposal_pay_count}}</p>
            </div>
          </div>
        </div>
        <!-- 方案内容  -->
        <div
          id="product-detail"
          data-anchor="product-detail"
        >
          <h2 class="twoh2 ">
            <span class="tpointer"></span>
            <span class="ttxt">卡项内容</span>
          </h2>
          <div class="mgl-12 mgt-16">
            <a-row class="mgb-30 ">
              <span class="leftBabletxt">消耗总数</span>
              <span class="rightBabletxt">{{ totalConsumption+'项' }}</span>
            </a-row>
            <!-- 生成的表格 -->
            <div class="guigeTablebox  mgb-30">
              <a-table
                :pagination="false"
                row-key="product_id_add"
                :columns="plan.columns"
                :data-source="goodSpecstableData"
                class="guigeTable guigeTableSpec"
                :rowClassName="getRowClassname"
              >
                <template
                  slot="_1"
                  slot-scope="action, record"
                >
                  {{  record.goods_name?record.goods_name:'--' }}
                </template>
                <template
                  slot="_2"
                  slot-scope="action, record"
                >
                  {{  record.goods_price?('￥'+ record.goods_price):'--' }}
                </template>
                <!-- 类型 -->
                <template
                  slot="_3"
                  slot-scope="action, record"
                >
                  <span v-if="[1,3].includes(record.goods_type)">客装</span>
                  <span v-else-if="[4,2].includes(record.goods_type)">店装</span>
                  <span v-else-if="[5,6].includes(record.goods_type)">服务</span>
                  <span v-else-if="record.goods_type==7">储值卡</span>
                  <span v-else-if="[8,9].includes(record.goods_type)">卡项</span>
                  <span v-else>--</span>
                </template>
                <!-- 数量 -->
                <template
                  slot="_5"
                  slot-scope="action, record"
                >
                  <span v-if="record.group_id">{{ record.goods_limit_count }}</span>
                  <span v-else>--</span>
                </template>
              </a-table>
            </div>
          </div>
        </div>
        <!-- 其他信息 -->
        <div
          class="mgt-40"
          id="product-user"
          data-anchor="product-user"
        >
          <h2 class="twoh2 mgb-20">
            <span class="tpointer"></span>
            <span class="ttxt">其他信息</span>
          </h2>
          <a-row class="mgb-30 mgl-12">
            <span class="leftBabletxt">上架状态</span>
            <span class="rightBabletxt">{{form.is_show?'下架':'上架'}}</span>
          </a-row>
          <a-row class="mgb-30 mgl-12">
            <span class="leftBabletxt">库存</span>
            <span class="rightBabletxt">{{form.goods_stock}}</span>
            <span class="leftBabletxt">实际销量</span>
            <span class="rightBabletxt">{{form.goods_sales}}</span>
          </a-row>
          <a-row class="mgb-30 mgl-12 flex ">
            <span class="leftBabletxt pdt-5">轮播图</span>
            <div
              class=" "
              v-if="form.goods_slider&&form.goods_slider.length"
            >
              <span
                class="swichers"
                v-for="(it,idx) in form.goods_slider"
                :key="idx"
              >
                <img
                  :src="it"
                  alt=""
                >
              </span>
            </div>
            <span
              class="rightBabletxt"
              v-else
            >--</span>
          </a-row>
          <a-row class="mgb-30 mgl-12 flex">
            <span class="leftBabletxt  pdt-5">封面</span>
            <div
              class="swichers "
              v-if="form.goods_image"
            >
              <img
                :src="form.goods_image"
                alt=""
                class=""
              >

            </div>
            <span
              class="rightBabletxt"
              v-else
            >--</span>
          </a-row>
          <a-row class="mgb-30 mgl-12 flex">
            <span class="leftBabletxt ">描述</span>
            <div class="flex-1 ">{{ form.goods_desc || '--'}}</div>
          </a-row>
          <a-row class="mgb-30 mgl-12 flex">
            <span class="leftBabletxt pdt-5">产品详情</span>
            <div class="flex ">
              <shop-editor
                class="goods_editor"
                @transfer="getUser"
                v-model="form.goods_content"
              >
              </shop-editor>
            </div>
          </a-row>
        </div>
      </a-layout-content>

    </div>
  </div>

</template>
<script>
import moment from 'moment';
import config from '../config';
import {
  Getplansdetails
} from '@/api/mall'
import { GetgoodsbrandsoptionsList } from '@/api/commodity'

import UploadImage from '../modules/drag'
import ShopEditor from '../modules/wangeditor'
import skuSetting from '../../../components/intimate/skuSetting/index.vue'
import Msg from '@/components/mrj-menu/msg'
import storage from 'store'
import axios from 'axios'
import { TagsSider } from '@/components'
import { pinyin } from 'pinyin-pro';
export default {
  inject: ['reload'],
  components: { UploadImage, ShopEditor, skuSetting, TagsSider },
  data() {
    return {
      config,
      imgflag: false,
      shoptabs: [
        {
          id: "product-base",
          label: "基本信息",
        },
        {
          id: "product-detail",
          label: "方案内容",
        },
        {
          id: "product-user",
          label: "其他信息",
        },
      ],
      activeKey: 'product-base',
      setId: '',
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      headers: {
        "Authorization": localStorage.getItem("token"),
        "Content-Type": 'multipart/form-data'
      },
      goodSpecstableData: [],
      form: {//表单数据
        goods_id: undefined,
        goods_name: undefined,
        goods_bn: undefined,
        goods_unit: undefined,
        is_give: 0,//0不允许 
        is_show: 1,
        goods_type_hz: 0,//合作项目 需要联动 goods_type   
        goods_attr: undefined,
        goods_attr_val: [{ product_image: '', product_price: '', product_bn: "" }],
        goods_desc: undefined,
        goods_image: undefined,
        goods_slider: undefined,
        goods_type: 1,
        goods_supplier: 1,
        goods_brand: undefined,
        finance_category_id: [],
        store_category_id: [],
        goods_category: [],
        goods_content: undefined,
        goods_shou_type: 1,
        goods_shou_end_time: null
      },
      isforgoodsorserviceorpaln: 1,//1产品 2 服务 3方案
      brandsList: [],
      plan: {
        columns: [
          {
            title: '组合',
            dataIndex: 'isGroup',
            key: '_0',
            scopedSlots: { customRender: '_0' },
            width: 22,
            customRender: (text, row, index) => {
              const obj = {
                children: (row.group_name || row.group_id) ? '组合' : ' ',
                attrs: {}
              }
              obj.attrs.rowSpan = this.myArray[index];
              return obj
            },
            customCell: (record, rowIndex) => {
              if (record.group_name || record.group_id) {
                return {
                  style: {
                    'text-align': 'center',
                    'padding-left': '0px',
                    'background-color': '#F6F9F9',

                  },
                  class: "guigeTableSpec_th0"
                };
              }
            }
          },
          {
            title: '商品名称',
            dataIndex: 'goods_name',
            key: '_1',
            ellipsis: true,
            // width: 80,
            scopedSlots: { customRender: '_1' },
          },
          {
            title: '标准单价',
            dataIndex: 'goods_price',
            key: '_2',
            ellipsis: true,
            scopedSlots: { customRender: '_2' },
          },
          {
            title: '类型',
            dataIndex: 'goods_type',
            key: '_3',
            ellipsis: true,
            scopedSlots: { customRender: '_3' },
          },
          {//todo
            title: '最多消耗量',
            dataIndex: 'goods_limit_count',
            key: '_5',
            ellipsis: true,
            scopedSlots: { customRender: '_5' },
          },
          {
            title: '数量',
            dataIndex: 'group_limit_count',
            key: '_6',
            ellipsis: true,
            scopedSlots: { customRender: '_6' },
            customRender: (text, row, index) => {
              // let children = (<div> {!row.group_limit_status ? '不限' : row.group_id ? row.goods_limit_count : row.group_limit_count} </div>)
              let children = (<div> {row.group_id ? row.group_limit_status ? '不限' : row.group_limit_count : row.goods_limit_status ? row.goods_limit_count : '不限'} </div>)

              const obj = {
                children: children,
                attrs: {}
                // row.group_limit_type   group_limit_count
              }
              obj.attrs.rowSpan = this.myArray[index];
              return obj
            },
            customCell: (record, rowIndex) => {
              if (record.rowSpan > 1) {
                return {
                  style: {
                    'text-align': 'center',
                    'padding-left': '0px',
                    'background-color': '#fff',
                  },
                  class: "guigeTableSpec_th1",
                };
              }
            }
          },
        ],

      },
      totalConsumption: 0,
      loading: false,
      myArray: [],
    };
  },
  created() {
    if (this.$route.query.type == 1) {
    }
    if (this.$route.query.type == 3) {
      this.getBrands()
      this.openDatails()
    }
  },
  methods: {
    moment,
    // 描点
    handleWrapScroll(rec) {
      this.activeKey = this.$utils.onHandleWrapScroll(rec, this.shoptabs)
    },

    // 富文本
    getUser(e) {
      console.log(e);
      this.form.goods_content = e
    },
    changeRoute() {
      this.$router.replace({ path: this.$route.fullpath ? this.$route.fullpath : this.$route.path });
      let newobject = storage.get('seconderyMenuObj')
      Msg.$emit('getpathname', newobject)

      this.$forceUpdate();
    },
    getRowClassname(record) {
      if (record.isGroup != null) {
        console.log(record.isGroup, "isGroup");
        return 'guigeTableSpec_th'
      }
    },
    openDatails() {
      Getplansdetails({ pool_goods_id: this.$route.query.id }).then(res => {
        console.log(this.brandsList);
        this.form = Object.assign(this.form, res.data)
        let str = res.data.goods_bn
        console.log(str);
        console.log(res.data);
        this.goods_name = this.form.goods_name

        console.log(this.form.goods_bn, "goods_bn");
        this.form.goods_unit = this.form.goods_unit ? this.form.goods_unit : undefined
        this.form.goods_type = this.form.goods_type == 3 ? 1 : this.form.goods_type == 1 ? 1 : 2
        this.form.goods_brand = this.form.goods_brand ? this.form.goods_brand : undefined
        this.form.goods_supplier = this.form.goods_supplier + ''

        this.form.goods_slider = JSON.parse(this.form.goods_slider)
        this.form.goods_attr_val.forEach((el, idx) => {
          el.product_id_add = el.goods_id
        })


        let fileList = []
        // 回显轮播图
        this.form.goods_slider.forEach((val, key) => {
          fileList.push({
            uid: '-' + key,
            // name: '' + val.name, // 文件名
            status: 'done',
            url: '' + val
          })
        })

        if (this.$refs.uploadCom && this.$refs.uploadCom.fileList) {
          this.$refs.uploadCom.fileList = fileList
        }
        // 回显bn
        this.form.goods_bn = str
        // 回显品牌
        this.brandsList.forEach(el => {
          if (el.brand_id == this.form.goods_brand) {
            this.form.goods_brand = el.brand_name
          }
        })
        // 回显方案商品组合列表

        let goods_list = this.form.product_list;
        let groupList = this.form.group_list
        // 匹配 groupList
        // 回显消耗总数
        // group_id?
        goods_list.forEach((el, idx) => {
          groupList.forEach((m, n) => {
            if (el.group_id == m.proposal_group_id) {
              goods_list[idx].group_limit_count = m.group_limit_count
            }
          })
        })
        // let goods_list = this.form.goods_list;
        let arr = [];
        let num = 0
        goods_list.forEach(val => {
          if (val.group_limit_count) num = num + val.group_limit_count

          let obj = {
            ...val,
            goods_name: val.goods_name,
            group_name: val.group_id || '',
            group_limit_status: val.group_limit_status == 1 ? true : false,
            goods_limit_status: val.goods_limit_status == 1 ? true : false,
            // goods_limit_count: val.goods_limit_count,
            // group_limit_count: !val.group_id ? val.goods_limit_count : val.group_limit_count
          }
          if (!obj.group_id) {
            obj.group_limit_count = obj.goods_limit_count
          }
          arr.push(obj)
        })
        this.totalConsumption = num
        this.goodSpecstableData = arr;
        this.setRowSpan(this.goodSpecstableData, '2');

      })
    },
    // 设置每一行的rowSpan
    setRowSpan(data, i) {
      //保存上一个name
      var x = "";
      //相同name出现的次数
      var count = 0;
      //该name第一次出现的位置
      var startindex = 0;

      for (var i = 0; i < data.length; i++) {
        //这里是合并name列，根据 情况 自己完善
        var val = data[(i)].group_id ? data[(i)].group_id : data[(i)].group_name
        // var val = data[(i)].group_id;
        // var val = data[(i)].group_name;
        if (val) {
          if (i == 0) {
            x = val;
            count = 1;
            this.myArray[0] = 1
          } else {
            if (val == x) {
              count++;
              this.myArray[startindex] = count;
              this.myArray[i] = 0
            } else {
              count = 1;
              x = val;
              startindex = i;
              this.myArray[i] = 1
            }
          }
        }

      }
      
      console.log(this.myArray, '/ this.myArray');
    },
    // 
    onChangeQuan(e) {
      let that = this;
      console.log('e :>> ', e);
      that.loading = true;
      setTimeout(function () {
        that.loading = false;
        console.log('加载框已关闭...')
      }, 50);

    },
    getBrands() {
      GetgoodsbrandsoptionsList().then(res => {
        console.log(res, "getBrands");
        if (res.error_code == 0) {
          this.brandsList = res.data
        }
      })
    },

    changeGglable(e) {
      // if (e.target.value == 1  && this.form.goods_attr.length > 0) {
      if (e.target.value == 1 && this.$route.query.type == 3) {
        this.goods_specs.isSingle = 2
        let self = this
        this.$confirm({
          icon: 'exclamation-circle',
          closable: true,
          title: '提示',
          content: '切换规格模式，将自动下架各渠道的全部规格，需重新上架至各渠道，请谨慎操作！',
          okText: '确定',
          okType: 'primary',
          cancelText: '取消',
          onOk() {
            self.$confirm({
              icon: 'exclamation-circle',
              closable: true,
              title: '提示',
              content: '  规格模式已切换至【单规格】模式，各渠道已下架当前商品，请重新上架新规格至各销售渠道；',
              okText: '确定',
              okType: 'primary',
              cancelText: '取消',
              onOk() {

                self.goods_specs.isSingle = 1
                self.form.goods_attr = undefined
                self.form.goods_attr_val = [{ product_image: '', product_price: '', product_bn: "" }]

              },
              onCancel() { },
            })
          },
          onCancel() { },
        })
      }
    },














  },
  computed: {
    goods_name: {
      get() {
        return this.form.goods_name
      },
      set(val) {
        this.form.goods_name = val
        this.form.goods_bn = ''
        let that = this
        let a = pinyin(val, { toneType: 'none' })
        let b = a.split(' ')
        let arr = []
        b.forEach(el => {
          arr.push(el.slice(0, 1))
        })
        arr = arr.join('')
        this.form.goods_bn = arr
      },
    },
  }
};
</script>
<style lang="less" scoped>
.add {
  display: flex;
  font-family: PingFangSC-Medium, PingFang SC;
  padding: 14px 18px 13px 13px;
  flex: 1;
  position: relative;

  /deep/ .ant-table-footer {
    background: @gray-border-background;
  }
}
.main-ctn-2 {
  padding: 30px 0 0 35px;
  margin: 12px 0 12px 12px;
  width: 100%;
  height: calc(100vh - 157px);
  background: #fff;
  overflow-y: auto;
}
.wh30Img {
  width: 42px;
  height: 32px;
  margin-right: 10px;
  box-shadow: 0px 2px 4px 0px rgba(204, 209, 228, 0.35);
  border-radius: 2px;
  border: 1px solid #a3a8ca;
}
.twoh3 {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  .tpointer {
    display: inline-block;
    width: 4px;
    height: 4px;
    background: @primaryColor;
    border-radius: 1px;
    margin-right: 5px;
  }
  .ttxt {
    height: 18px;
    font-size: 14px;
    font-weight: 500;
    color: @fontColor1;
    margin-right: 8px;
  }
}
.desc_css {
  max-height: 64vh;
  overflow: auto;
}
.item-r-l {
  height: 22px;
  font-size: 14px;
  font-weight: 400;
  height: 22px;
  line-height: 22px;
  margin-bottom: 15px;
  margin-top: 15px;
  display: flex;
  .rText {
    color: @fontColor1;
  }
  .lText {
    color: rgba(45, 56, 53, 0.8);
    width: 123px;
    display: block;
  }
}
.history-top {
  text-align: center;
  height: 31px;
  line-height: 31px;
  background: #fff0e8;
  border: 1px solid #ffd8c3;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #f47c5b;
  .text {
    font-size: 12px;
    color: #b7978e;
  }
}
.total_balance {
  display: flex;
  align-items: center;
  background: #f3f9f8;
  border-radius: 4px;
  font-size: 22px;
  padding-left: 24px;
  height: 44px;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: @baseColor11;
  .span {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #323f3e;
  }
}
.twoh2 {
  display: flex;
  margin-bottom: 0px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: @fontColor4;
  .tpointer {
    display: inline-block;
    width: 3px;
    height: 10px;
    background: @primaryColor;
    border-radius: 1px;
    margin: 5px 5px 0 0;
  }
  .ttxt {
    margin-right: 8px;
    // height: 22px;
    // font-size: 14px;
    // font-weight: 500;
    // color: @fontColor1;
    // line-height: 22px;
  }
  .tag-sm {
    height: 22px;
    background: #e6f5f4;
    border-radius: 11px;
    padding: 0 6px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(45, 56, 53, 0.8);
    line-height: 22px;
  }
  .onsales {
    display: inline-block;
    width: 40px;
    height: 22px;
    background: #ecffeb;
    border-radius: 4px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #217f13;
    line-height: 22px;
    text-align: center;
  }
  .offsales {
    width: 40px;
    height: 22px;
    background: rgba(86, 87, 102, 0.15);
    border-radius: 4px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: @fontColor3;
    line-height: 22px;
    text-align: center;
  }
}
.shopCodes {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: @fontColor4;
  padding-left: 9px;
  margin: 4px 0 20px 0;
}
.twoh1 {
  display: flex;
  margin-bottom: 12px;
  align-items: center;
  .tpointer {
    display: inline-block;
    width: 3px;
    height: 10px;
    background: @primaryColor;
    border-radius: 1px;
    margin-right: 5px;
  }
  .ttxt {
    height: 22px;
    font-size: 14px;
    font-weight: 500;
    color: @fontColor1;
    margin-right: 8px;
  }
}

.y-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.detail-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .img {
    width: 88px;
    height: 88px;
    background: #cbe3e1;
    border-radius: 8px;
    margin-right: 18px;
  }
  .title {
    font-size: 18px;
    font-weight: 600;
  }
  .text {
    font-size: 14px;
    font-weight: 400;
    color: #323f3e;
  }
  .text-sm {
    font-size: 14px;
    font-weight: 400;
    color: #778581;
  }
}
.card-bg {
  height: 150px;
  background: #f3f9f8;
  border-radius: 4px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  .title {
    height: 22px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #323f3e;
    line-height: 22px;
    margin-bottom: 15px;
    margin-left: 15px;
  }
  .money {
    height: 30px;
    font-size: 26px;
    font-family: DINAlternate-Bold, DINAlternate;
    font-weight: bold;
    line-height: 30px;
  }
}
.shortcut-menu {
  width: 130px;
  :deep(.ant-anchor-wrapper) {
    background-color: transparent;
  }
}
/deep/.ant-table-middle
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-body
  > table
  > .ant-table-thead
  > tr
  > th,
/deep/.ant-table-middle
  > .ant-table-content
  > .ant-table-body
  > table
  > .ant-table-thead
  > tr
  > th {
  padding: 1px 8px;
}
/deep/.detailsTable .ant-table-row > td:nth-child(1),
/deep/.detailsTable .ant-table-thead > tr:first-child > th:first-child {
  padding-left: 24px !important;
}
.row-body {
  .top {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    .text-title {
      margin-left: 10px;
      font-size: 16px;
      font-weight: 400;
      color: @btnColorfont1;
    }
    .yuan-blue {
      width: 38px;
      height: 38px;
      text-align: center;
      line-height: 38px;
      color: #ffffff;
      border-radius: 100%;
      background: @primaryColor;
      border: 1px solid @dropdownBs5a;
    }
    .dian {
      color: #e5e8e5;
      margin: 0 10px;
    }
    .yuan {
      width: 38px;
      height: 38px;
      text-align: center;
      line-height: 38px;
      color: @fontColor7;
      border-radius: 100%;
      background: #fdfdff;
      border: 1px solid @btnColorshodew;
    }
  }
}
.goods_editor {
  width: 600px;
  height: 259px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #cdd5d8;
  overflow: hidden;
  overflow-y: auto;
  pointer-events: none;
}
.font-22 {
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: @fontColor1;
}
// 面包屑相关
.mrj-layout-tabs-custom {
  border-bottom: 1px solid @breadcrumbdownlineColor;
}
.leftarrow {
  color: @breadcrumbbackiconColor !important;
  border: 1px solid @breadcrumbbackborderColor !important;
  outline: none;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  text-align: center;
  box-sizing: border-box;
  line-height: 10px;
  margin-top: 1px;
  padding-top: 1.2px;
  &:hover {
    color: @breadcrumbbackiconHColor !important;
    border: 1px solid @breadcrumbbackborderHColor!important;
    outline: none;
  }
  .arrleft {
    margin-top: -1px;
    &:hover {
      color: @baseColor70 !important;
    }
  }
  .meiye-fanghui {
    font-size: 14px;
    margin-left: -1px;
  }
}
.leftarrow_hover {
  color: @baseColor70 !important;
  border: 1px solid !important;
  outline: none;
}
.mrj-layout-tabs-custom-index span {
  color: @baseColor11;
  font-size: 14px;
}
.mrj-layout-tabs-custom-index:hover .anticon {
  color: @baseColor70 !important;
  border: 1px solid !important;
  outline: none;
}
.mrj-layout-tabs-custom-fenge {
  color: @breadcrumblineColor;
}
.mrj-layout-tabs-custom-inner-page {
  color: @fontColor4;
  font-size: 14px;
}
.samerow {
  width: 100%;
  /deep/.ant-form-item {
    display: flex;
    align-items: center;
    width: 100%;
  }
  /deep/ .ant-cascader-picker-label {
    display: inline-block;
  }
}
.samerow1 {
  /deep/ .ant-form-item-children {
    display: flex;
    align-items: center;
    width: 60%;
    justify-content: space-between;
  }
}

.samerow2 {
  /deep/.ant-form-item-label-left {
    margin-left: 68px;
  }
}
#styledatas {
  div {
    width: auto !important;
  }
}
// 单选另一种框
.checkqystatus {
  width: 180px;
  height: 32px;
  line-height: 32px;
  background: @labelDbgColor;
  // box-shadow: inset 0px 3px 0px 0px @labelDinsertColor;
  border-radius: 4px;
  border: 1px solid @labelDborderColor;
  box-sizing: border-box;
  padding: 3px;
  font-size: 16px;
  .cqt1 {
    width: 90px;
    height: 28px;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    color: @labelDfontColor;
    cursor: pointer;
    border: 1px solid transparent;
    text-align: center;
    line-height: 26px;
    &:hover {
      color: @labelHfontColor;
    }
  }
  .cqt1A {
    width: 90px;
    height: 28px;
    background: @labelAbgColor;
    box-shadow: inset 0px -2px 0px 0px @labelAinsertColor;
    // box-shadow: inset 0px -2px 0px 0px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    border: 1px solid @labelAborderColor;
    color: @labelAfontColor;
    text-align: center;
    line-height: 26px;
  }
}
//单规格
.sigleguige {
  .tabelHeader {
    // width: 1605px;
    width: 100%;
    height: 48px;
    line-height: 48px;
    background: rgba(241, 246, 245, 0.6);
    border-radius: 4px;
    position: relative;
    padding-left: 25px;
    display: flex;
    align-items: center;
    &::after {
      content: " ";
      width: 100%;
      height: 2px;
      background: #e8edec;
      position: absolute;
      top: -4px;
      left: 0;
    }
    .tabelHeader_items {
      width: 188px;
      text-align: left;
      display: inline-block;
    }
    .tabelHeader_items-278 {
      width: 278px;
    }
  }
  .tableBody {
    width: 100%;
    height: 72px;
    display: flex;
    align-items: center;
    padding-left: 25px;
    border-bottom: 1px solid #e6eded;
    .tabelBody_items {
      width: 188px;
      text-align: left;
    }
    .tabelBody_items-278 {
      width: 278px;
    }
  }
}
.two {
  /deep/ .avatar-uploader,
  /deep/ .avatar-uploader .ant-upload {
    width: 48px !important;
    height: 48px !important;
    // padding-top: 4px;
  }
}

/deep/.two .avatar-uploader {
  width: 48px !important;
  height: 48px !important;
  padding-top: 2px;
  margin: 0 !important;
}
/deep/.two .ant-upload.ant-upload-select-picture-card {
  margin: 0 !important;
}
/deep/.uploaderguige .ant-upload-picture-card-wrapper .ant-upload {
  width: 48px !important;
  height: 48px !important;
  padding: 0;
  margin: 0;
}
.baseColor11 {
  color: @baseColor11 !important;
}
.htmlboxshops {
  &:focus-visible {
    border: none !important;
  }
}
.addGuigeRules {
  margin-top: 32px;
  font-size: 16px;
  font-weight: 400;
  color: #2d3835;
  i {
    color: #2d3835;
  }
}
.guigeline {
  width: 2px;
  height: 31px;
  background: #e8edec;
  margin: 0 0 0 48px;
}
.batchSet {
  height: 73px;
  background: #f1fafa;
  line-height: 73px;
  font-size: 14px;
  font-weight: 400;
  color: @fontColor4;
  .batchSet_h2 {
    font-size: 14px;
    font-weight: 500;
    color: @fontColor4;
    margin: 0 52px 0 10px;
  }
  .guigeImg {
    height: 73px;
    line-height: 73px;
    flex-wrap: nowrap;
    .guigeImg_txt {
      font-size: 14px;
      display: inline-block;
      font-weight: 400;
      color: @fontColor4;
      height: 73px;
      line-height: 73px;
    }
    /deep/ .ant-upload-picture-card-wrapper .ant-upload {
      margin: 0;
      width: 48px !important;
      height: 48px !important;
      border-radius: 4px;
      // border: 1px dashed #9ba7a7;
    }
    /deep/.ant-upload.ant-upload-select-picture-card:hover {
      border-color: @primaryColor;
    }
  }
}
.singlePrice {
}
.guigeTablebox {
  margin-top: 8px;
}
.goods_specx_add {
  position: relative;
  .guigeline {
    position: absolute;
    top: 0;
    right: 0;
  }
}
/deep/ .avatar-uploader2.ant-upload-picture-card-wrapper .ant-upload {
  width: 66px !important;
  height: 66px !important;
  // border: 1px solid #d9d9d9;
  background: #fff;
}

.boldimgspan {
  position: relative;
  .meiye-close-boldimg {
    position: absolute;
    top: -21px;
    right: -8px;
    font-size: 12px;
  }
}
.addBox .add .mgb-32 {
  margin-bottom: 32px;
}

// 多规格样式修改
/deep/.guigeTablebox .ant-table .ant-table-body .datestyle {
  text-align: center;
  border-right: 1px solid @listbodylineColor !important;
}

/deep/.guigeTablebox .ant-table .pdl-24 {
  padding-left: 6% !important;
}
/deep/.guigeTablebox .ant-table .ant-table-thead .pdl-24 {
  padding-left: 6% !important;
}
/deep/.selectGg .ant-select-dropdown-menu {
  padding-bottom: 0 in !important;
}
/deep/.mgb-18.ant-form-item {
  margin-bottom: 18px;
}
.wid-132 {
  width: 132px;
}
/deep/.samerow .ant-cascader-picker-label {
  // display: none;
  box-shadow: none;
}
.pladd {
  color: rgba(45, 56, 53, 0.4);
}
.add .addright .last-btns-step2 {
  height: 68px;
  width: calc(100vw - 317px);
  position: fixed;
  bottom: 19px;
  padding-top: 16px;
  background: #fff;
  border-top: 1px solid #d8eceb;
  z-index: 2;
  padding-left: 40px;
}
.mgl-28 {
  margin-left: 28px;
}
.chooseSku {
  display: block;
}
.mgr-20 {
  margin-right: 40px;
}
.mgt-20 {
  margin-top: 20px;
}
.mgb-20 {
  margin-bottom: 20px;
}
.mgt-16 {
  margin-top: 16px;
}
.mgb-30 {
  margin-bottom: 30px;
}
.mgb-42 {
  margin-bottom: 42px;
}
.pdt-5 {
  padding-top: 5px;
}
.leftBabletxt {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  color: @fontColor3;
  width: 123px;
  text-align: left;
}
.rightBabletxt {
  display: inline-block;
  width: 262px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: @fontColor4;
}
.swichers {
  width: 60px;
  height: 60px;
  margin-right: 12px;
  img {
    width: 60px;
    height: 60px;
  }
}
.cardsbox {
  width: calc(100% - 35px);
  background: #fafcfc;
  border-radius: 8px;
  border: 1px solid #d2e6e4;
  height: 116px;
  margin-right: 48px;
  margin-bottom: 30px;
  // justify-content: space-evenly;
  .cardbox1 {
    width: calc((100% - 35px) / 5);
    height: 36px;
    text-align: center;
    border-right: 1px solid #d2e6e4;
    .cr1_p1 {
      font-size: 14px;
      font-weight: 400;
      color: #4d5e59;
      margin: 0;
      padding: 0;
      margin-bottom: -1px;
    }
    .cr1_p2 {
      font-size: 22px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #131010;
      margin: 0;
      padding: 0;
    }
  }
  .cardbox1:last-child {
    border-right: none;
  }
}
/deep/ .ant-table-tbody tr.guigeTableSpec_th {
  &:hover {
    .guigeTableSpec_th0 {
      color: @baseColor11 !important;
    }
  }
}
/deep/ .ant-table-tbody tr.guigeTableSpec_th .guigeTableSpec_th0 {
  color: @baseColor11 !important;
}
</style>