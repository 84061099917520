<template>
  <div class="addBox">
    <!--  面包屑  -->
    <div
      class="mrj-layout-tabs-custom flex align-item-center"
      style="z-index:1;"
    >
      <div
        @click="changeRoute"
        class="mrj-layout-tabs-custom-index flex align-item-center cursor-pointer"
      >
        <div
          type="left"
          class="leftarrow"
          :class="imgflag?'leftarrow_hover':''"
          @mouseenter="imgflag=true"
          @mouseleave="imgflag=false"
        >
          <i class="meiye-icon meiye-fanghui"></i>
        </div>

        <span
          @mouseenter="imgflag=true"
          @mouseleave="imgflag=false"
        >{{ $route.meta.title }}</span>
      </div>
      <span class="mrj-layout-tabs-custom-fenge">/</span>
      <span class="mrj-layout-tabs-custom-inner-page"> 查看 </span>
    </div>
    <!-- 内容区 -->
    <div class="add"> 
      <TagsSider
        :tabs="shoptabs"
        v-model="activeKey"
      />
      <a-layout-content
        class="main-ctn-2"
        id="mrj-scroll-main"
        @scroll="handleWrapScroll"
      >
        <!-- 基本信息 -->
        <div
          id="product-base"
          data-anchor="product-base"
        >
          <h2 class="twoh2">
            <span class="tpointer"></span>
            <span class="ttxt"> {{form.goods_name}} </span>
            <span :class="form.is_show==1?'onsales':'offsales'">{{form.is_show==1?'在售':'停售'}}</span>
          </h2>
          <h4 class="shopCodes">商品码：{{ form.goods_bn }}</h4>
          <a-row class="mgb-30 mgl-12">
            <span class="leftBabletxt">来源</span>
            <span class="rightBabletxt">总部创建</span>
            <span class="leftBabletxt">商品别名</span>
            <span class="rightBabletxt">{{form.goods_name}}</span>
          </a-row>
          <a-row class="mgb-30 mgl-12">
            <span class="leftBabletxt">商品售价</span>
            <span class="rightBabletxt baseColor11">¥{{form.goods_price}}</span>
            <span class="leftBabletxt">面值</span>
            <span class="rightBabletxt baseColor11">￥{{form.face_value}}</span>
          </a-row>
          <a-row class="mgb-30 mgl-12">
            <span class="leftBabletxt">品牌</span>
            <span class="rightBabletxt">{{ form.goods_brand || '' }}</span>
            <span class="leftBabletxt">商城分类</span>
            <span class="rightBabletxt">{{ form.store_category_name||'--'}}</span>
          </a-row>
        </div>
        <!-- 使用设置  -->
        <div
          id="product-detail"
          data-anchor="product-detail"
        >
          <h2 class="twoh2 ">
            <span class="tpointer"></span>
            <span class="ttxt">使用设置</span>
          </h2>
          <div class="mgl-12 mgt-16">
            <a-row class="mgb-30 ">
              <div class=" ">
                <a-radio-group
                  v-model="isforgoodsorserviceorpaln"
                  class="lable_radiogroup"
                  style="width:360px;"
                  @change="changeGglable"
                >
                  <a-radio-button
                    :value="1"
                    style="width:120px;text-align: center;"
                  >
                    适用产品
                  </a-radio-button>
                  <a-radio-button
                    :value="2"
                    style="width:120px;text-align: center;"
                  >
                    适用服务
                  </a-radio-button>
                  <a-radio-button
                    :value="3"
                    style="width:120px;text-align: center;"
                  >
                    适用方案
                  </a-radio-button>
                </a-radio-group>
              </div>
            </a-row>

            <!-- 生成的表格  -->
            <div class="guigeTablebox  mgb-30">
              <!-- 不适用 -->
              <span v-if="isforgoodsorserviceorpaln==1 && form.rule_entity_type == 0">不适用</span>
              <span v-if="isforgoodsorserviceorpaln==2 && form.rule_service_type == 0">不适用</span>
              <span v-if="isforgoodsorserviceorpaln==3 && form.rule_proposal_type == 0">不适用</span>
              <!-- 全部 -->
              <span v-if="isforgoodsorserviceorpaln==1 && form.rule_entity_type == 1">全部产品</span>
              <span v-if="isforgoodsorserviceorpaln==2 && form.rule_service_type == 1">全部服务</span>
              <span v-if="isforgoodsorserviceorpaln==3 && form.rule_proposal_type == 1">全部方案</span>
              <!-- 部分产品 -->
              <a-table
                v-if="isforgoodsorserviceorpaln==1 && form.rule_entity_type > 1"
                :pagination="false"
                row-key="product_id_add"
                :columns="config.carddateils.columns"
                :data-source="mockData"
                class="guigeTable"
              >

                <template
                  slot="_5"
                  slot-scope="action, record"
                >
                  <span v-if="[1,2,3,4].includes(record.goods_type)">商品</span>
                  <!-- <span v-else-if="[4,2].includes(record.goods_type)">店装</span> -->
                  <span v-else-if="[5,6].includes(record.goods_type)">服务</span>
                  <span v-else-if="record.goods_type==7">储值卡</span>
                  <span v-else-if="[8,9].includes(record.goods_type)">卡项</span>
                  <span v-else>--</span>
                </template>
                <template
                  slot="_6"
                  slot-scope="action, record"
                >
                  <a-tooltip>
                    <template slot="title">
                      {{  record.finance_category_name+'/'+record.goods_category_name+'/'+record.store_category_name }}
                    </template>
                    {{  record.finance_category_name }}
                  </a-tooltip>

                </template>
                <!-- product_market_price -->
                <template
                  slot="_4"
                  slot-scope="action, record"
                >
                  {{ "￥"+record.goods_price }}
                </template>

              </a-table>
              <!-- 部分服务 -->
              <a-table
                v-if="isforgoodsorserviceorpaln==2 && form.rule_service_type > 1"
                :pagination="false"
                row-key="product_id_add"
                :columns="config.carddateils.columns"
                :data-source="mockData2"
                class="guigeTable"
              >
                <template
                  slot="_5"
                  slot-scope="action, record"
                >
                  <span v-if="[1,2,3,4].includes(record.goods_type)">商品</span>
                  <!-- <span v-else-if="[4,2].includes(record.goods_type)">店装</span> -->
                  <span v-else-if="[5,6].includes(record.goods_type)">服务</span>
                  <span v-else-if="record.goods_type==7">储值卡</span>
                  <span v-else-if="[8,9].includes(record.goods_type)">卡项</span>
                  <span v-else>--</span>
                </template>
                <template
                  slot="_6"
                  slot-scope="action, record"
                >
                  <a-tooltip>
                    <template slot="title">
                      {{  record.finance_category_name+'/'+record.goods_category_name+'/'+record.store_category_name }}
                    </template>
                    {{  record.finance_category_name }}
                  </a-tooltip>

                </template>
                <!-- product_market_price -->
                <template
                  slot="_4"
                  slot-scope="action, record"
                >
                  {{ "￥"+record.goods_price }}
                </template>
              </a-table>
              <!-- 部分方案 -->
              <a-table
                v-if="isforgoodsorserviceorpaln==3 && form.rule_proposal_type > 1"
                :pagination="false"
                row-key="product_id_add"
                :columns="config.carddateils.columns"
                :data-source="mockData3"
                class="guigeTable"
              >
                <template
                  slot="_5"
                  slot-scope="action, record"
                >
                  <span v-if="[1,2,3,4].includes(record.goods_type)">商品</span>
                  <!-- <span v-else-if="[4,2].includes(record.goods_type)">店装</span> -->
                  <span v-else-if="[5,6].includes(record.goods_type)">服务</span>
                  <span v-else-if="record.goods_type==7">储值卡</span>
                  <span v-else-if="[8,9].includes(record.goods_type)">卡项</span>
                  <span v-else>--</span>
                </template>
                <template
                  slot="_6"
                  slot-scope="action, record"
                >
                  <a-tooltip>
                    <template slot="title">
                      {{  record.finance_category_name+'/'+record.goods_category_name+'/'+record.store_category_name }}
                    </template>
                    {{  record.finance_category_name }}
                  </a-tooltip>

                </template>
                <template
                  slot="_4"
                  slot-scope="action, record"
                >
                  {{ "￥"+record.goods_price }}
                </template>
              </a-table>

            </div>
          </div>
        </div>
        <!-- 其他信息 -->
        <div
          class="mgt-40"
          id="product-user"
          data-anchor="product-user"
        >
          <h2 class="twoh2 mgb-20">
            <span class="tpointer"></span>
            <span class="ttxt">其他信息</span>
          </h2>
          <a-row class="mgb-30 mgl-12">
            <span class="leftBabletxt">上架状态</span>
            <span class="rightBabletxt">{{form.is_show?'下架':'上架'}}</span>
          </a-row>
          <a-row class="mgb-30 mgl-12">
            <span class="leftBabletxt"><!-- 虚拟销量 --> 库存 </span>
            <span class="rightBabletxt">{{form.goods_stock}}</span>
            <span class="leftBabletxt">实际销量</span>
            <span class="rightBabletxt">{{form.goods_sales}}</span>
          </a-row>
          <a-row class="mgb-30 mgl-12 flex ">
            <span class="leftBabletxt pdt-5">轮播图</span>
            <div
              class=" "
              v-if="form.goods_slider&&form.goods_slider.length"
            >
              <span
                class="swichers"
                v-for="(it,idx) in form.goods_slider"
                :key="idx"
              >
                <img
                  :src="it"
                  alt=""
                >
              </span>
            </div>
            <span
              class="rightBabletxt"
              v-else
            >--</span>
          </a-row>
          <a-row class="mgb-30 mgl-12 flex">
            <span class="leftBabletxt  pdt-5">封面</span>
            <div
              class="swichers "
              v-if="form.goods_image"
            >
              <img
                :src="form.goods_image"
                alt=""
                class=""
              >

            </div>
            <span
              class="rightBabletxt"
              v-else
            >--</span>
          </a-row>
          <a-row class="mgb-30 mgl-12 flex">
            <span class="leftBabletxt ">描述</span>
            <div class="flex-1 ">{{ form.goods_desc || '--'}}</div>
          </a-row>
          <a-row class="mgb-30 mgl-12 flex">
            <span class="leftBabletxt pdt-5">产品详情</span>
            <div class="flex ">
              <shop-editor
                class="goods_editor"
                @transfer="getUser"
                v-model="form.goods_content"
              >
              </shop-editor>
            </div>
          </a-row>
        </div>
      </a-layout-content>

    </div>
  </div>

</template>
<script>
import moment from 'moment';
import config from '../config';
import {
  Getcardsdetails,
  Getcardsrules,
} from '@/api/mall'
import { GetgoodsbrandsoptionsList } from '@/api/commodity'

import UploadImage from '../modules/drag'
import ShopEditor from '../modules/wangeditor'
import skuSetting from '../../../components/intimate/skuSetting/index.vue'
import Msg from '@/components/mrj-menu/msg'
import storage from 'store'
import axios from 'axios'
import { TagsSider } from '@/components'
import { pinyin } from 'pinyin-pro';
export default {
  inject: ['reload'],
  components: { UploadImage, ShopEditor, skuSetting, TagsSider },
  data() {
    return {
      config,
      imgflag: false,
      shoptabs: [
        {
          id: "product-base",
          label: "基本信息",
        },
        {
          id: "product-detail",
          label: "使用设置",
        },
        {
          id: "product-user",
          label: "其他信息",
        },
      ],
      activeKey: 'product-base',
      setId: '',
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      headers: {
        "Authorization": localStorage.getItem("token"),
        "Content-Type": 'multipart/form-data'
      },
      goodSpecstableData: [],
      form: {//表单数据
        goods_id: undefined,
        goods_name: undefined,
        goods_bn: undefined,
        goods_unit: undefined,
        is_give: 0,//0不允许 
        is_show: 1,
        goods_type_hz: 0,//合作项目 需要联动 goods_type   
        goods_attr: undefined,
        goods_attr_val: [{ product_image: '', product_price: '', product_bn: "" }],
        goods_desc: undefined,
        goods_image: undefined,
        goods_slider: undefined,
        goods_type: 1,
        goods_supplier: 1,
        goods_brand: undefined,
        finance_category_id: [],
        store_category_id: [],
        goods_category: [],
        goods_content: undefined,
        goods_shou_type: 1,
        goods_shou_end_time: null
      },
      isforgoodsorserviceorpaln: 1,//1产品 2 服务 3方案
      brandsList: [],
      productPage: 1,//产品当前页
      mockData: [],//部分产品 列表
      productPage2: 1,//服务当前页
      mockData2: [],//部分服务 列表
      productPage3: 1,//方案当前页
      mockData3: [],//部分方案 列表
    };
  },
  created() {
    if (this.$route.query.type == 1) {
    }
    if (this.$route.query.type == 3) {
      this.getBrands()
      this.openDatails()
    }
  },
  methods: {
    moment,
    // 描点
    handleWrapScroll(rec) {
      this.activeKey = this.$utils.onHandleWrapScroll(rec, this.shoptabs)
    },

    // 富文本
    getUser(e) {
      console.log(e);
      this.form.goods_content = e
    },
    changeRoute() {
      this.$router.replace({ path: this.$route.fullpath ? this.$route.fullpath : this.$route.path });
      let newobject = storage.get('seconderyMenuObj')
      Msg.$emit('getpathname', newobject)
      this.$forceUpdate();
    },
    openDatails() {
      let id = this.$route.query.id
      // let id = 137
      Getcardsdetails({ pool_goods_id: id }).then(res => {
        console.log(this.brandsList);
        this.form = Object.assign(this.form, res.data)
        let str = res.data.goods_bn
        console.log(str);
        console.log(res.data);
        this.goods_name = this.form.goods_name

        console.log(this.form.goods_bn, "goods_bn");
        this.form.goods_unit = this.form.goods_unit ? this.form.goods_unit : undefined
        this.form.goods_type = this.form.goods_type == 3 ? 1 : this.form.goods_type == 1 ? 1 : 2
        this.form.goods_brand = this.form.goods_brand ? this.form.goods_brand : undefined
        this.form.goods_supplier = this.form.goods_supplier + ''
        this.form.goods_slider = JSON.parse(this.form.goods_slider)

        // 回显轮播图
        let fileList = []
        this.form.goods_slider.forEach((val, key) => {
          fileList.push({
            uid: '-' + key,
            // name: '' + val.name, // 文件名
            status: 'done',
            url: '' + val
          })
        })
        if (this.$refs.uploadCom && this.$refs.uploadCom.fileList) {
          this.$refs.uploadCom.fileList = fileList
        }
        // 回显bn
        this.form.goods_bn = str
        // 回显品牌
        this.brandsList.forEach(el => {
          if (el.brand_id == this.form.goods_brand) {
            this.form.goods_brand = el.brand_name
          }
        })
        // 回显  产品  服务 方案 
        //  0不适用、1适用全部实物, 2 指定财务分类下实物、3指定品牌下实物、
        //  4指定实物、5指定商城分类下实物、
        //  6 指定门店分类下实物
        this.form.entity_ids = []
        this.form.service_ids = []
        this.form.proposal_ids = []
        if (this.form.rule_list && this.form.rule_list.length) {
          this.form.rule_list.forEach(el => {
            if (el.rule_goods_type == 1) { //产品
              this.form.entity_ids.push(el.rel_id + '')
            }
            if (el.rule_goods_type == 2) { //服务
              this.form.service_ids.push(el.rel_id + '')
            }
            if (el.rule_goods_type == 3) {//方案
              this.form.proposal_ids.push(el.rel_id + '')
            }
          })

        }
        if (this.form.rule_entity_type == 0) {
          // 不适用
        } else if (this.form.rule_entity_type == 1) {
          // 全部
        } else { //指定产品
          this.loadProduct()
        }
        if (this.form.rule_service_type == 0) {
        } else if (this.form.rule_service_type == 1) {
        } else {
          this.loadProduct2()
        }
        if (this.form.rule_proposal_type == 0) {
        } else if (this.form.rule_proposal_type == 1) {
        } else {
          this.loadProduct3()
        }
      })
    },
    getBrands() {
      GetgoodsbrandsoptionsList().then(res => {
        console.log(res, "getBrands");
        if (res.error_code == 0) {
          this.brandsList = res.data
        }
      })
    },

    changeGglable(e) {

    },
    // 产品
    async loadProduct() {
      let that = this;
      let data = {
        'goods_id': this.form.goods_id,
        'rule_type': 1,
        "limit": 100,
        "page": that.productPage
      }
      Getcardsrules(data).then(res => {
        let productList = res.data.list ? res.data.list : [];
        // that.consumable.ztotal = res.data.total;
        if (productList.length > 0) {
          that.mockData = res.data.list
          that.productPage++;
          that.loadProduct();
        } else {
          console.log(that.mockData, "------------mockData");//全部门店或者商城数据
        }
      })
    },

    async loadProduct2() {
      let that = this;
      let data = {
        'goods_id': this.form.goods_id,
        'rule_type': 2,
        "limit": 100,
        "page": that.productPage2
      }
      Getcardsrules(data).then(res => {
        let productList = res.data.list ? res.data.list : [];
        if (productList.length > 0) {
          that.mockData2 = res.data.list
          that.productPage2++;
          that.loadProduct2();
        } else {
          // 
          console.log(that.mockData2, "------------mockData2");//全部门店或者商城数据

        }

      })

    },
    async loadProduct3() {
      let that = this;
      let data = {
        'goods_id': this.form.goods_id,
        'rule_type': 3,
        "limit": 100,
        "page": that.productPage3
      }
      Getcardsrules(data).then(res => {
        let productList = res.data.list ? res.data.list : [];
        // that.consumable.ztotal = res.data.total;
        if (productList.length > 0) {
          that.mockData3 = res.data.list
          that.productPage3++;
          that.loadProduct3();
        } else {
          console.log(that.mockData3, "------------mockData3");//全部门店或者商城数据

        }

      })

    },















  },
  computed: {
    goods_name: {
      get() {
        return this.form.goods_name
      },
      set(val) {
        this.form.goods_name = val
        this.form.goods_bn = ''
        let that = this
        let a = pinyin(val, { toneType: 'none' })
        let b = a.split(' ')
        let arr = []
        b.forEach(el => {
          arr.push(el.slice(0, 1))
        })
        arr = arr.join('')
        this.form.goods_bn = arr
      },
    },
  }
};
</script>
<style lang="less" scoped>
.add {
  display: flex;
  font-family: PingFangSC-Medium, PingFang SC;
  padding: 14px 18px 13px 13px;
  flex: 1;
  position: relative;

  /deep/ .ant-table-footer {
    background: @gray-border-background;
  }
}
.main-ctn-2 {
  padding: 30px 0 0 35px;
  margin: 12px 0 12px 12px;
  width: 100%;
  height: calc(100vh - 157px);
  background: #fff;
  overflow-y: auto;
}
.wh30Img {
  width: 42px;
  height: 32px;
  margin-right: 10px;
  box-shadow: 0px 2px 4px 0px rgba(204, 209, 228, 0.35);
  border-radius: 2px;
  border: 1px solid #a3a8ca;
}
.twoh3 {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  .tpointer {
    display: inline-block;
    width: 4px;
    height: 4px;
    background: @primaryColor;
    border-radius: 1px;
    margin-right: 5px;
  }
  .ttxt {
    height: 18px;
    font-size: 14px;
    font-weight: 500;
    color: @fontColor1;
    margin-right: 8px;
  }
}
.desc_css {
  max-height: 64vh;
  overflow: auto;
}
.item-r-l {
  height: 22px;
  font-size: 14px;
  font-weight: 400;
  height: 22px;
  line-height: 22px;
  margin-bottom: 15px;
  margin-top: 15px;
  display: flex;
  .rText {
    color: @fontColor1;
  }
  .lText {
    color: rgba(45, 56, 53, 0.8);
    width: 123px;
    display: block;
  }
}
.history-top {
  text-align: center;
  height: 31px;
  line-height: 31px;
  background: #fff0e8;
  border: 1px solid #ffd8c3;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #f47c5b;
  .text {
    font-size: 12px;
    color: #b7978e;
  }
}
.total_balance {
  display: flex;
  align-items: center;
  background: #f3f9f8;
  border-radius: 4px;
  font-size: 22px;
  padding-left: 24px;
  height: 44px;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: @baseColor11;
  .span {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #323f3e;
  }
}
.twoh2 {
  display: flex;
  margin-bottom: 0px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: @fontColor4;
  .tpointer {
    display: inline-block;
    width: 3px;
    height: 10px;
    background: @primaryColor;
    border-radius: 1px;
    margin: 5px 5px 0 0;
  }
  .ttxt {
    margin-right: 8px;
    // height: 22px;
    // font-size: 14px;
    // font-weight: 500;
    // color: @fontColor1;
    // line-height: 22px;
  }
  .tag-sm {
    height: 22px;
    background: #e6f5f4;
    border-radius: 11px;
    padding: 0 6px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(45, 56, 53, 0.8);
    line-height: 22px;
  }
  .onsales {
    display: inline-block;
    width: 40px;
    height: 22px;
    background: #ecffeb;
    border-radius: 4px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #217f13;
    line-height: 22px;
    text-align: center;
  }
  .offsales {
    width: 40px;
    height: 22px;
    background: rgba(86, 87, 102, 0.15);
    border-radius: 4px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: @fontColor3;
    line-height: 22px;
    text-align: center;
  }
}
.shopCodes {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: @fontColor4;
  padding-left: 9px;
  margin: 4px 0 20px 0;
}
.twoh1 {
  display: flex;
  margin-bottom: 12px;
  align-items: center;
  .tpointer {
    display: inline-block;
    width: 3px;
    height: 10px;
    background: @primaryColor;
    border-radius: 1px;
    margin-right: 5px;
  }
  .ttxt {
    height: 22px;
    font-size: 14px;
    font-weight: 500;
    color: @fontColor1;
    margin-right: 8px;
  }
}

.y-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.detail-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .img {
    width: 88px;
    height: 88px;
    background: #cbe3e1;
    border-radius: 8px;
    margin-right: 18px;
  }
  .title {
    font-size: 18px;
    font-weight: 600;
  }
  .text {
    font-size: 14px;
    font-weight: 400;
    color: #323f3e;
  }
  .text-sm {
    font-size: 14px;
    font-weight: 400;
    color: #778581;
  }
}
.card-bg {
  height: 150px;
  background: #f3f9f8;
  border-radius: 4px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  .title {
    height: 22px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #323f3e;
    line-height: 22px;
    margin-bottom: 15px;
    margin-left: 15px;
  }
  .money {
    height: 30px;
    font-size: 26px;
    font-family: DINAlternate-Bold, DINAlternate;
    font-weight: bold;
    line-height: 30px;
  }
}
.shortcut-menu {
  width: 130px;
  :deep(.ant-anchor-wrapper) {
    background-color: transparent;
  }
}
/deep/.ant-table-middle
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-body
  > table
  > .ant-table-thead
  > tr
  > th,
/deep/.ant-table-middle
  > .ant-table-content
  > .ant-table-body
  > table
  > .ant-table-thead
  > tr
  > th {
  padding: 1px 8px;
}
/deep/.detailsTable .ant-table-row > td:nth-child(1),
/deep/.detailsTable .ant-table-thead > tr:first-child > th:first-child {
  padding-left: 24px !important;
}
.row-body {
  .top {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    .text-title {
      margin-left: 10px;
      font-size: 16px;
      font-weight: 400;
      color: @btnColorfont1;
    }
    .yuan-blue {
      width: 38px;
      height: 38px;
      text-align: center;
      line-height: 38px;
      color: #ffffff;
      border-radius: 100%;
      background: @primaryColor;
      border: 1px solid @dropdownBs5a;
    }
    .dian {
      color: #e5e8e5;
      margin: 0 10px;
    }
    .yuan {
      width: 38px;
      height: 38px;
      text-align: center;
      line-height: 38px;
      color: @fontColor7;
      border-radius: 100%;
      background: #fdfdff;
      border: 1px solid @btnColorshodew;
    }
  }
}
.goods_editor {
  width: 600px;
  height: 259px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #cdd5d8;
  overflow: hidden;
  overflow-y: auto;
  pointer-events: none;
}
.font-22 {
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: @fontColor1;
}
// 面包屑相关
.mrj-layout-tabs-custom {
  border-bottom: 1px solid @breadcrumbdownlineColor;
}
.leftarrow {
  color: @breadcrumbbackiconColor !important;
  border: 1px solid @breadcrumbbackborderColor !important;
  outline: none;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  text-align: center;
  box-sizing: border-box;
  line-height: 10px;
  margin-top: 1px;
  padding-top: 1.2px;
  &:hover {
    color: @breadcrumbbackiconHColor !important;
    border: 1px solid @breadcrumbbackborderHColor!important;
    outline: none;
  }
  .arrleft {
    margin-top: -1px;
    &:hover {
      color: @baseColor70 !important;
    }
  }
  .meiye-fanghui {
    font-size: 14px;
    margin-left: -1px;
  }
}
.leftarrow_hover {
  color: @baseColor70 !important;
  border: 1px solid !important;
  outline: none;
}
.mrj-layout-tabs-custom-index span {
  color: @baseColor11;
  font-size: 14px;
}
.mrj-layout-tabs-custom-index:hover .anticon {
  color: @baseColor70 !important;
  border: 1px solid !important;
  outline: none;
}
.mrj-layout-tabs-custom-fenge {
  color: @breadcrumblineColor;
}
.mrj-layout-tabs-custom-inner-page {
  color: @fontColor4;
  font-size: 14px;
}
.samerow {
  width: 100%;
  /deep/.ant-form-item {
    display: flex;
    align-items: center;
    width: 100%;
  }
  /deep/ .ant-cascader-picker-label {
    display: inline-block;
  }
}
.samerow1 {
  /deep/ .ant-form-item-children {
    display: flex;
    align-items: center;
    width: 60%;
    justify-content: space-between;
  }
}

.samerow2 {
  /deep/.ant-form-item-label-left {
    margin-left: 68px;
  }
}
#styledatas {
  div {
    width: auto !important;
  }
}
// 单选另一种框
.checkqystatus {
  width: 180px;
  height: 32px;
  line-height: 32px;
  background: @labelDbgColor;
  // box-shadow: inset 0px 3px 0px 0px @labelDinsertColor;
  border-radius: 4px;
  border: 1px solid @labelDborderColor;
  box-sizing: border-box;
  padding: 3px;
  font-size: 16px;
  .cqt1 {
    width: 90px;
    height: 28px;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    color: @labelDfontColor;
    cursor: pointer;
    border: 1px solid transparent;
    text-align: center;
    line-height: 26px;
    &:hover {
      color: @labelHfontColor;
    }
  }
  .cqt1A {
    width: 90px;
    height: 28px;
    background: @labelAbgColor;
    box-shadow: inset 0px -2px 0px 0px @labelAinsertColor;
    // box-shadow: inset 0px -2px 0px 0px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    border: 1px solid @labelAborderColor;
    color: @labelAfontColor;
    text-align: center;
    line-height: 26px;
  }
}
//单规格
.sigleguige {
  .tabelHeader {
    // width: 1605px;
    width: 100%;
    height: 48px;
    line-height: 48px;
    background: rgba(241, 246, 245, 0.6);
    border-radius: 4px;
    position: relative;
    padding-left: 25px;
    display: flex;
    align-items: center;
    &::after {
      content: " ";
      width: 100%;
      height: 2px;
      background: #e8edec;
      position: absolute;
      top: -4px;
      left: 0;
    }
    .tabelHeader_items {
      width: 188px;
      text-align: left;
      display: inline-block;
    }
    .tabelHeader_items-278 {
      width: 278px;
    }
  }
  .tableBody {
    width: 100%;
    height: 72px;
    display: flex;
    align-items: center;
    padding-left: 25px;
    border-bottom: 1px solid #e6eded;
    .tabelBody_items {
      width: 188px;
      text-align: left;
    }
    .tabelBody_items-278 {
      width: 278px;
    }
  }
}
.two {
  /deep/ .avatar-uploader,
  /deep/ .avatar-uploader .ant-upload {
    width: 48px !important;
    height: 48px !important;
    // padding-top: 4px;
  }
}

/deep/.two .avatar-uploader {
  width: 48px !important;
  height: 48px !important;
  padding-top: 2px;
  margin: 0 !important;
}
/deep/.two .ant-upload.ant-upload-select-picture-card {
  margin: 0 !important;
}
/deep/.uploaderguige .ant-upload-picture-card-wrapper .ant-upload {
  width: 48px !important;
  height: 48px !important;
  padding: 0;
  margin: 0;
}
.baseColor11 {
  color: @baseColor11 !important;
}
.htmlboxshops {
  &:focus-visible {
    border: none !important;
  }
}
.addGuigeRules {
  margin-top: 32px;
  font-size: 16px;
  font-weight: 400;
  color: #2d3835;
  i {
    color: #2d3835;
  }
}
.guigeline {
  width: 2px;
  height: 31px;
  background: #e8edec;
  margin: 0 0 0 48px;
}
.batchSet {
  height: 73px;
  background: #f1fafa;
  line-height: 73px;
  font-size: 14px;
  font-weight: 400;
  color: @fontColor4;
  .batchSet_h2 {
    font-size: 14px;
    font-weight: 500;
    color: @fontColor4;
    margin: 0 52px 0 10px;
  }
  .guigeImg {
    height: 73px;
    line-height: 73px;
    flex-wrap: nowrap;
    .guigeImg_txt {
      font-size: 14px;
      display: inline-block;
      font-weight: 400;
      color: @fontColor4;
      height: 73px;
      line-height: 73px;
    }
    /deep/ .ant-upload-picture-card-wrapper .ant-upload {
      margin: 0;
      width: 48px !important;
      height: 48px !important;
      border-radius: 4px;
      // border: 1px dashed #9ba7a7;
    }
    /deep/.ant-upload.ant-upload-select-picture-card:hover {
      border-color: @primaryColor;
    }
  }
}
.singlePrice {
}
.guigeTablebox {
  margin-top: 8px;
}
.goods_specx_add {
  position: relative;
  .guigeline {
    position: absolute;
    top: 0;
    right: 0;
  }
}
/deep/ .avatar-uploader2.ant-upload-picture-card-wrapper .ant-upload {
  width: 66px !important;
  height: 66px !important;
  // border: 1px solid #d9d9d9;
  background: #fff;
}

.boldimgspan {
  position: relative;
  .meiye-close-boldimg {
    position: absolute;
    top: -21px;
    right: -8px;
    font-size: 12px;
  }
}
.addBox .add .mgb-32 {
  margin-bottom: 32px;
}

// 多规格样式修改
/deep/.guigeTablebox .ant-table .ant-table-body .datestyle {
  text-align: center;
  border-right: 1px solid @listbodylineColor !important;
}

/deep/.guigeTablebox .ant-table .pdl-24 {
  padding-left: 6% !important;
}
/deep/.guigeTablebox .ant-table .ant-table-thead .pdl-24 {
  padding-left: 6% !important;
}
/deep/.selectGg .ant-select-dropdown-menu {
  padding-bottom: 0 in !important;
}
/deep/.mgb-18.ant-form-item {
  margin-bottom: 18px;
}
.wid-132 {
  width: 132px;
}
/deep/.samerow .ant-cascader-picker-label {
  // display: none;
  box-shadow: none;
}
.pladd {
  color: rgba(45, 56, 53, 0.4);
}
.add .addright .last-btns-step2 {
  height: 68px;
  width: calc(100vw - 317px);
  position: fixed;
  bottom: 19px;
  padding-top: 16px;
  background: #fff;
  border-top: 1px solid #d8eceb;
  z-index: 2;
  padding-left: 40px;
}
.mgl-28 {
  margin-left: 28px;
}
.chooseSku {
  display: block;
}
.mgr-20 {
  margin-right: 40px;
}
.mgt-20 {
  margin-top: 20px;
}
.mgb-20 {
  margin-bottom: 20px;
}
.mgt-16 {
  margin-top: 16px;
}
.mgb-30 {
  margin-bottom: 30px;
}
.mgb-42 {
  margin-bottom: 42px;
}
.pdt-5 {
  padding-top: 5px;
}
.leftBabletxt {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  color: @fontColor3;
  width: 123px;
  text-align: left;
}
.rightBabletxt {
  display: inline-block;
  width: 262px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: @fontColor4;
}
.swichers {
  width: 60px;
  height: 60px;
  margin-right: 12px;
  img {
    width: 60px;
    height: 60px;
  }
}
</style>